import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Banner from "../../DefaultComponent/appComponent/Banner";
import ExamItem from "./ExamItem";
import Loader from "../../DefaultComponent/Loader";
import bannerImg from "../../../template/img/certificate_banner.png"
import { Button } from "react-bootstrap";
import TermsAndConditionsModal from "./TermsAndConditions";
import Quiz from "./Quiz";
import StatusModal from "../../DefaultComponent/appComponent/StatusModal";
import { Outlet } from "react-router-dom";
// import bannerImg from './../../../template/img/certificate_banner.png';



const ExamRoot = () => {
    const [loading, setLoading] = useState(false)
    const [currentUrl, setCurrentUrl] = useState("")
    const [showModal,setModalShow] = useState(false) 
    const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
    const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);

    useEffect(() => {
        fetchUrl();
    }, [])
    const fetchUrl = () => {
        let urlElements = window.location.href.split('/');
        let urlElelement = (urlElements[3])
        setCurrentUrl(`/${urlElelement}`)
    }

    return (
        <React.Fragment>
            <Header />
            <div className=" app">
                {/* Sidebar */}
                <Sidebar url={currentUrl}/>
                {/* main comp */}
                <main>
                    {!loading ?
                        <>
                            <div className='mainpage' >
                                <div class="page_main">
                                    <div class="page_inner">
                                        <Outlet/>
                                    </div>
                                    
                                </div>


                            </div>
                        </>
                        :
                        <Loader />
                    }

                    {/* <Quiz
                        show={showModal}
                        backdrop={'static'}
                        onHide={() => {
                            setModalShow(false)}}
                    /> */}
                </main>
                
            </div>


        </React.Fragment>
    )
}

export default ExamRoot;

