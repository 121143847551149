
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import '../../template/all.scss';
import loginpage from "../../template/img/admin 1.svg";
import logo from "../../template/img/Group 1105.svg";
import styles from "./Loginpage.module.scss";

function Login_page (props) {
   const { keycloak } = useKeycloak()

const handleLogInOut = () => {
  if (keycloak.authenticated) {
    keycloak.logout()
    
  } else {
    keycloak.login()
  }
}


const getLogInOutText = () => {
  return keycloak.authenticated ? "Logout" : "Login"
}

// const getAdminMenuStyle = () => {
//   return keycloak.authenticated && isAdmin(keycloak) ? { "display": "block" } : { "display": "none" }
// }

// return (
//   <Menu stackable>
//     <Container>
//       <Menu.Item header>Movies UI</Menu.Item>

//       {/* <Dropdown item text='Admin' style={getAdminMenuStyle()}>
//         <Dropdown.Menu>
//           <Dropdown.Item as={NavLink} exact to="/movies" onClick={checkAuthenticated}>Movies</Dropdown.Item>
//           <Dropdown.Item as={NavLink} exact to="/wizard" onClick={checkAuthenticated}>Movie Wizard</Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown> */}
//       <Menu.Menu position='right'>
//         {keycloak.authenticated &&
//           <Dropdown text={`Hi ${getUsername()}`} pointing className='link item'>
//             <Dropdown.Menu>
  
//             </Dropdown.Menu>
//           </Dropdown>
//         }
//         <Menu.Item as={NavLink} exact to="/login" </Menu.Item>
//       </Menu.Menu>
//     </Container>


//   </Menu >
// )
// }

//   //  UserService.doLogin()
//   // const isLoggedIn = UserService.isLoggedIn();
//   // // console.log(isLoggedIn)
  return (
  <>
  
    <div className={`${styles.bg3} container-fluid`}>
      <div className={`row  ${styles.row_h100}`}>
        <div className={`col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-sm-12 col-12  ${styles.bg1}`}>
          <img src={loginpage} className="img-fluid " alt="" />
        </div>
        <div className={`col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-sm-12 col-12 ${styles.logo}`}>
          <img src={logo} className="img-fluid " alt="" />
          <div className='d-block text-center '>
          <p className={`my-5 ${styles.logintext}`}>Login</p>
          <button className={`btn lmsbtn w-25 my-5`} type="button " onClick={handleLogInOut}>{getLogInOutText()}
          </button>
          </div>
        </div>
      </div>
    </div>

  </>
);
} 


 export default Login_page;


