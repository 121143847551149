import moment from "moment";
import { ASSESSMENT, MOCK } from "./Constants";

export const cloneData = (data) => {
  data.appReducer["appLoginTime"] = moment().format("HH:mm");
  return JSON.parse(JSON.stringify(data));
};

export const getTimeInMillies = () => {
  return Math.round(new Date().getTime() / 1000);
};

export const fromQuiz = (type) => {
  return type === "QUIZ";
};

export const getAssessmentType = (type) => {
  return type === "QUIZ" ? ASSESSMENT : MOCK;
};

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const formatDate = (stringData, format) => {
  return stringData ? moment(stringData).format(format) : "";
};

export function removeBase64Prefix(
  base64String,
  type = "data:application/pdf;base64,"
) {
  const prefix = type;
  if (base64String.startsWith(prefix)) {
    return base64String.slice(prefix.length);
  } else {
    return base64String;
  }
}

export function capitalize(originalString) {
  const [firstChar, ...restOfString] = originalString;
  return [firstChar.toUpperCase(), ...restOfString].join("");
}
