
import axios from '../../axiosconfig';

// Batch Craetion
export function batchCreate(data) {
   return  new Promise((resolve,reject)=>{ 
    axios.post('batch/api/v1/lms-batch/batch/create',data,{
      headers: {
          //'Access-Control-Allow-Origin':'*',
          "Accept": "application/json",
          "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
    });
}
//batch Classified List
export function postBatchClassifiedList(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('batch/api/v1/lms-batch/batch/classified/list',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });

}
//batch List
export function batchList() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('batch/api/v1/lms-batch/batch/list')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}
//batchList in Status
export function batchListInStatus(inStatus) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`batch/api/v1/lms-batch/batch/list/${inStatus}/ui`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }
  //get batch in Id
  export function batchListInId(inId) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`batch/api/v1/lms-batch/batch/get/${inId}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }
    //delete batch 
    export function batchDelate(inId) {
        return  new Promise((resolve,reject)=>{ 
         axios.delete(`batch/api/v1/lms-batch/batch/delete/${inId}`)
           .then(function (response) {
             resolve(response.data);
           })
           .catch(function (error) {
             reject(error);
           });
         });
      }
//batch Edit
export function batchEdit(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.put('batch/api/v1/lms-batch/batch/edit',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  
  }


//batch Send for approval

export function batchSendForApproval(id) {
    return  new Promise((resolve,reject)=>{ 
     axios.put(`batch/api/v1/lms-batch/batch/sendForApproval/${id}`,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  
  }

//batch Validate

export function batchValidate(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.put('batch/api/v1/lms-batch/batch/validate',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  
  }




//classificationList
export function classificationList(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('batch/api/v1/lms-batch/batch/classified/list',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}