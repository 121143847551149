    export const UsersData=
    [
        {
            id:1,
            name:"oli ganguly",
            mobile:"7003618054",
            email:"oli.g@gmail.com",
            country:"India"
        },
        {
            id:2,
            name:"kritika ",
            mobile:"7003618054",
            email:"kritika.g@gmail.com",
            country:"India"
        },
        {
            id:3,
            name:"oli ganguly",
            mobile:"7003618054",
            email:"oli.g@gmail.com",
            country:"India"
        }
    ]
