import axios from "../../../axiosconfig";

//  View Marks for individual student
export function MarksForIndividualStudent(inAssessmentId, inUserId) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `assessment/api/v1/lms_assessment/attempt/mgmt/benerificaries/marks/${inAssessmentId}/${inUserId}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function assignmentSubmittedByBeneficiary(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "assessment/api/v1/lms_assessment/attempt/mgmt/submitted/assignment",
          data,
          {
            headers: {
              //'Access-Control-Allow-Origin':'*',
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  export function submitAssignment(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("assessment/api/v1/lms_assessment/attempt/mgmt/submit/assignment",
          data,
          {
            headers: {
              //'Access-Control-Allow-Origin':'*',
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  
// by id attempt/mgmt/benerificaries/marks
  export function beneficiaryMarks(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("assessment/api/v1/lms_assessment/attempt/mgmt/benerificaries/marks",
          data,
          {
            headers: {
              //'Access-Control-Allow-Origin':'*',
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  export function beneficiaryGradeMarks(id,ids) {
    return new Promise((resolve, reject) => {
      axios
        .get(`assessment/api/v1/lms_assessment/attempt/mgmt/benerificaries/marks/${id}/${ids}`,
          
          {
            headers: {
              //'Access-Control-Allow-Origin':'*',
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  