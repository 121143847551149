
import axios from '../../axiosconfig';
// interventions schools update
export function interventionsschoolsupdate(data) {
   return  new Promise((resolve,reject)=>{ 
    axios.post('admin/api/v1/lms-admin/interventions/schools/mapping/update',data,{
      headers: {
          //'Access-Control-Allow-Origin':'*',
          "Accept": "application/json",
          "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
        // console.log('Error on Authentication');
      });
    });
}
// interventions schools create
export function interventionsSchoolsMapping(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/interventions/schools/mapping/create',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

// interventions schools activate
export function interventionsschoolsactivate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/interventions/schools/mapping/activate',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// interventions schools all 
export function interventionsschoolsall(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/interventions/schools/mapping/${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

// interventions schools view 
export function interventionsschoolsview() {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/interventions/schools/mapping/view`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// interventions schools view 
export function interventionsschoolsdelete(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/interventions/schools/mapping/delete/${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

// interventions schools view 
export function allbeneficiarylistbyid(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`api/v1/lms-admin/beneficiary/list${id}`,id,{
    headers: {
        //'Access-Control-Allow-Origin':'*',
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
  })
    .then(function (response) {
      resolve(response.data);
    })
    .catch(function (error) {
      reject(error);
      // console.log('Error on Authentication');
    });
  });
}


// allbeneficiarylist
export function allbeneficiarylist() {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`api/v1/lms-admin/beneficiary/list`)
   .then(function (response) {
     resolve(response.data);
   })
   .catch(function (error) {
     reject(error);
     // console.log('Error on Authentication');
   });
 });
}
  //school based on intervention
  export function schoolMapInterventionList(id) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`admin/api/v1/lms-admin/interventions/schools/mapping/get/${id}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

  export function fetchStatistics(userId) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`assessment/api/v1/lms_assessment/data/mgmt/beneficiary/dashboard/${userId}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

  export function fetchTheToprPerformers(userRole, courseId, status) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`assessment/api/v1/lms_assessment/data/mgmt/beneficiary/leaderBoard/${userRole}/${courseId}/${status}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }


  


  //http://localhost:444/lms-assessment-service/api/v1/lms_assessment/data/mgmt/beneficiary/leaderBoard/1/23/true
  