
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useRef, useState } from 'react';
// import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { AssessmentSubmit, fetchDataOnNetworkFail, fetchTempDataOnPrevious, storeTempDataOnNext } from '../../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController';
import { getUserByUserId } from '../../../../services/ApiCaller/user/usercontroller';
import iconQ from '../../../../template/img/Q.svg';
import { successMessages } from '../../../../util/Messaging';
import ErrorMsg from '../../../DefaultComponent/ErrorMsg';
import SuccessMsg from '../../../DefaultComponent/SuccessMsg';
import CountdownTimer from './CountdownTimer';
import IndexList from './IndexList';
import './QuestionAns.scss';

const allTfOptions = [
   {
      id: "1",
      Label: "True",
      value: true
   },
   {
      id: "2",
      Label: "False",
      value: false
   }
];

export default function Questions(props) {
   const { questions, currentQues, setCurrentQues, asseId, userId,totaltime } = props;
   const { keycloak } = useKeycloak();
   const { ass_id } = useParams()
   const navigate = useNavigate();

   //Final Submitted Data
   const [storeData, setStoreData] = useState([])
   //Fill
   const [fillinBlanks, setFillInBlanks] = useState('')
   //True and false
   const [selectedOption, setSelectedOption] = useState("false");
   const [singleSelectedData, setSingleSelectedData] = useState('')

   const [selected, setSelected] = useState('')
   const [active, setActive] = useState(false)
   const [activeSingleQues, setActiveSingleQues] = useState('')
   const [enableSubmit, setEnableSubmit] = useState(false)
   const [mcqInfo, setMcqInfo] = useState([]);
   const[storeMcqInfo,setStoreMcqInfo]=useState([])
   const [storeLastData, setStoreLastData] = useState([])
   //SHOW sUCCESS MSGS
   const [successMsg, setSuccessMsg] = useState("")
   const [showSuccessMsg, setShowSuccessMsg] = useState(false)
   const [triggerSuccess, setTriggerSuccess] = useState(false)

   //SHOW ERROR MSGS
   const [errorMsg, setErrorMsg] = useState("")
   const [showPopup, setShowpopup] = useState(false)
   const [popup, setPopup] = useState(false)
   const [errorObj, setErrorObj] = useState({})

   const [getuserid, setGetuserid] = useState('')
   const [loading, setLoading] = useState(false)
   const [pushSpinner,setPushSpinner]=useState(false)
   const [data, setData] = useState([])
   const [countNext, setCountNext] = useState(0)
   const [green,setGreen]=useState([])
   // i want it to call once
   useEffect(() => {
      fetchQuestionIfNetworkFails()
   }, [currentQues])
   console.log("qyyy",questions)

   //fetch answer if network fails
   const fetchQuestionIfNetworkFails = () => {
      console.log(currentQues,"currentques")
      setStoreMcqInfo([])
      const resp = fetchDataOnNetworkFail(asseId, userId)
      resp.then((success) => {
         let data = []
        Object.values(success).map((item)=>{
         if(item.answers.length>0){
            data.push(item.questionId)
         }
        })
        console.log(success,currentQues,"success----------------")
        setGreen(data)
         if (success[currentQues].quesType === "Fill in the blanks") {
               setFillInBlanks(success[currentQues].answers[0])  
         }
         if (success[currentQues].quesType === "True and False") {
               setSelectedOption(success[currentQues].answers[0])
         }
         if (success[currentQues].quesType === "Single select MCQ") {
               setActiveSingleQues(success[currentQues].answers[0])
               setSingleSelectedData(success[currentQues].answers[0])
         }
         if (success[currentQues].quesType === "Match the following") {
               const first_arr = [];
               Object.values(success[currentQues].answers).map((item, index) => {first_arr.push(item.split("-")[0])})
               setListOne([...first_arr])
               const second_arr = [];
               Object.values(success[currentQues].answers).map((item, index) => {second_arr.push(item.split("-")[1])})
               setList([...second_arr])
           
         }
         if(success[currentQues].quesType==="Multiple select MCQ"){
            setStoreMcqInfo(success[currentQues].answers)
            // setMcqInfo(success[currentQues].answers)
         }

      }, error => {
         console.log("error///////", error)
      })
   }
   console.log("setStoreMcqInfosetStoreMcqInfosetStoreMcqInfo",storeMcqInfo)

   useEffect(() => {
      fetchuserList();
      setList(questions[currentQues].optionsTwo);
      setListOne(questions[currentQues].optionsOne);
      allButtons()
      // fetchBasedOnQuesId()
   }, [questions, currentQues, setCurrentQues]);

   //define ALL Index
   const allButtons = () => {
      setData([])
      Object.values(questions).map((item) => {
         const data = {
            label: item.srNo,
            value: item.id
         }
         setData((prevdata) => [...prevdata, data]);
      })
   }
   //fetch user_id
   const fetchuserList = () => {
      let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
      const response = getUserByUserId(filterdata)
      response.then((success) => {
         setGetuserid(success.id)
      })
   }
   //Single MCQ
   const handleClick = (i) => {
      setActiveSingleQues(i)
      setSingleSelectedData(i)
   }
   // fill in the blanks hANDLER
   const handleFillInBlanks = (value) => {
      setFillInBlanks(value)
   }
   //HANDLE NEXT 
   const handleNext = () => {
      setActive('')
      if (currentQues + 1 === Object.values(questions).length) {
         setEnableSubmit(true)
         setCountNext(countNext + 1)
      }
      if (questions && questions[currentQues].questionType === "Fill in the blanks") {
         setFillInBlanks('')
         setPushSpinner(true)
         const tempData = {
            "assessmentId": asseId,
            "userId": userId,
            "questionId": questions[currentQues].id,
            "quesType": questions[currentQues].questionType,
            "answers": [fillinBlanks],
         }
         const response = storeTempDataOnNext(tempData);
         response.then((success) => {
            setPushSpinner(false)
            const data = {
               questionId: questions[currentQues].id,
               answers: [fillinBlanks]
            }
            setStoreData((prevdata) => [...prevdata, data]);
            if (currentQues + 1 != Object.values(questions).length) {
               setCurrentQues(currentQues + 1)
               setFillInBlanks('')
            }
         }, error => {
            setFillInBlanks('')
            setPushSpinner(false)
         })
      }
      if (questions && questions[currentQues].questionType === "True and False") {
         setPushSpinner(true)
         const tempData = {
            "assessmentId": asseId,
            "userId": userId,
            "questionId": questions[currentQues].id,
            "quesType": questions[currentQues].questionType,
            "answers": [selectedOption],
         }
         const response = storeTempDataOnNext(tempData);
         response.then((success) => {
            setPushSpinner(false)
            const data = {
               questionId: questions[currentQues].id,
               answers: [selectedOption]
            }
            setStoreData((prevdata) => [...prevdata, data]);
            if (currentQues + 1 != Object.values(questions).length) {
               setCurrentQues(currentQues + 1)
               setSelectedOption('')
            }
         }, error => {
            setPushSpinner(false)
            setSelectedOption('')
         });
      }
      if (questions && questions[currentQues].questionType === "Match the following") {
         //console.log(listflg)
         setPushSpinner(true)
         if (listflg === false) {
            let bldata = []
            const data = {
               questionId: questions[currentQues].id,
               answers: bldata
            }
            setStoreData((prevdata) => [...prevdata, data])
            if (currentQues + 1 != Object.values(questions).length) {
               setCurrentQues(currentQues + 1)
            }
            setList([])
            setListOne([])
            setListflg(false)
         }
         else {
            const tempData = {
               "assessmentId": asseId,
               "userId": userId,
               "questionId": questions[currentQues].id,
               "quesType": questions[currentQues].questionType,
               "answers": matchedArray,
            }
            const response = storeTempDataOnNext(tempData);
            response.then((success) => {
               setPushSpinner(false)
               const data = {
                  questionId: questions[currentQues].id,
                  answers: matchedArray
               }
               
               setStoreData((prevdata) => [...prevdata, data])
               if (currentQues + 1 != Object.values(questions).length) {
                  setCurrentQues(currentQues + 1)
                  // setList([])
                  // setListOne([])
                  setListflg(false)
               }
            }, error => {
               // setList([])
               // setListOne([])
               setListflg(false)
               setPushSpinner(false)
            })


         }
      }
      if (questions && questions[currentQues].questionType === "Multiple select MCQ") {
         setStoreMcqInfo([])
         Object.keys(mcqInfo).forEach(values => {
            if (mcqInfo[values] === '') {
               delete mcqInfo[values];
            }
         });
         const propertyValues = Object.values(mcqInfo);
         setPushSpinner(true)
         const tempData = {
            "assessmentId": asseId,
            "userId": userId,
            "questionId": questions[currentQues].id,
            "quesType": questions[currentQues].questionType,
            "answers": storeMcqInfo.length===0?propertyValues:storeMcqInfo,
         }
         const response = storeTempDataOnNext(tempData);
         response.then((success) => {
            setPushSpinner(false)
            const data = {
               questionId: questions[currentQues].id,
               answers: propertyValues
            }
            setStoreData((prevdata) => [...prevdata, data]);
            if (currentQues + 1 != Object.values(questions).length) {
               setCurrentQues(currentQues + 1)
               setMcqInfo([])
               setStoreMcqInfo([])
              
            }
         }, error => {
            setPushSpinner(false)
            setMcqInfo([])
            setStoreMcqInfo([])
         });

      }
      if (questions && questions[currentQues].questionType === "Single select MCQ") {
         setPushSpinner(true)
         const tempData = {
            "assessmentId": asseId,
            "userId": userId,
            "questionId": questions[currentQues].id,
            "quesType": questions[currentQues].questionType,
            "answers": [singleSelectedData],
         }
         const response = storeTempDataOnNext(tempData);
         response.then((success) => {
            setPushSpinner(false)
            const data = {
               questionId: questions[currentQues].id,
               answers: [singleSelectedData]
            }
            setStoreData((prevdata) => [...prevdata, data]);
            if (currentQues + 1 != Object.values(questions).length) {
               setCurrentQues(currentQues + 1)
               setSingleSelectedData('')
            }
         }, error => {
            setPushSpinner(false)
            setSingleSelectedData('')
         });
      }

   }
   const handlePrevious = () => {
      setCurrentQues(currentQues - 1)
   }
   const handleSkip = () => {
      if (currentQues === Object.values(questions).length - 1) {
         setEnableSubmit(true)
      } else {
         setCurrentQues(currentQues + 1)
      }
   }
   //SUBMITTING FINAL DATA
   const handleSubmit = () => {
      // setLoading(true);
      const final = [...storeData, ...storeLastData]
      const Data = JSON.stringify({
         "assessmentId": ass_id,
         "userId": getuserid,
         "data": final
      });
      console.log(Data)
      const resp = AssessmentSubmit(Data);
      console.log(props,'props----------------')
      resp.then(
         (success) => {
            setLoading(false)
            navigate(props.mock ? '/MockView':'/AssessmentBeneficiaryView')
            setSuccessMsg(successMessages.modal.submitmeg)
            setShowSuccessMsg(true)
            setTriggerSuccess(true)
            setTimeout(() => { navigate(props.mock ? '/MockView':'/AssessmentBeneficiaryView') }, 3000)
         },
         (error) => {
            setLoading(false)
            navigate(props.mock ? '/MockView':'/AssessmentBeneficiaryView')
            setTimeout(() => { navigate(props.mock ? '/MockView':'/AssessmentBeneficiaryView') }, 3000)
            setErrorObj(error)
            setShowpopup(true);
            setPopup(true)
         }
      );
   }

   //HANDLE CHANGE OF MULTIPLE SELECT
   const toggleHandler = (item) => () => {
      setStoreMcqInfo([])
      setMcqInfo((state) => ({ ...state, [item]: state[item] ? '' : item }));
   };






   const dragItem = useRef();
   const dragOverItem = useRef();

   const [list, setList] = useState([]);
   const [listOne, setListOne] = useState([]);
   const [listflg, setListflg] = useState(false);
   console.log("setListsetListsetListsetListsetListsetList", list)
   //   setList(questions[currentQues].optionsTwo)
   const dragStart = (e, position) => {
      dragItem.current = position;
      //console.log(e.target.innerHTML);
   };

   const dragEnter = (e, position) => {
      dragOverItem.current = position;
      //console.log(e.target.innerHTML);
   };

   const drop = (e) => {
      const copyListItems = [...list];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setList(copyListItems);
      setListflg(true);
   };


   let array1 = listOne;
   let array2 = list;
   const concatArr = (arr1, arr2, sep) => arr1.map(function (num, idx) {
      return num?.toString()?.concat(sep, (arr2[idx])?.toString())
   });
   let matchedArray = concatArr(array1, array2, "-")

   const handleChangeTf = (event) => {
      setSelectedOption(event.target.value)
   }

   //Handle Index point
   const hansleIndex = (data) => {
      console.log(data)

      setActive(data.label)
      setCurrentQues(parseInt(data.label - 1))
      fetchBasedOnQuesId(data.value)
   }
   const fetchBasedOnQuesId = (QuesId) => {
      setFillInBlanks('')
      setSelectedOption('')
      setStoreMcqInfo([])
      const resp = fetchTempDataOnPrevious(asseId, userId, QuesId)
      resp.then((success) => {
         console.log("success is is is ",success)
         if(success.quesType==="Fill in the blanks"){
            setFillInBlanks(success.answers[0])
         }
         if (success.quesType === "True and False") {
            setSelectedOption(success.answers[0])
         }
         if (success.quesType === "Single select MCQ") {
            setActiveSingleQues(success.answers[0])
            setSingleSelectedData(success.answers[0])
         }
         if (success.quesType === "Match the following") {
            const first_arr = [];
            Object.values(success.answers).map((item, index) => {first_arr.push(item.split("-")[0])})
            setListOne([...first_arr])
            const second_arr = [];
            Object.values(success.answers).map((item, index) => {second_arr.push(item.split("-")[1])})
            setList([...second_arr])
         }
         if(success.quesType === "Multiple select MCQ"){
            setStoreMcqInfo(success.answers)
         }
      }, error => {

      }
      )
   }
    const renderMcq = () => {
      return (
         <div>
            {
               questions[currentQues].options &&
               Object.values(questions[currentQues].options).map((item, index) => {
                  return (
                     <div>
                        <div className="form-check formcheckmcq checkbox-label">
                           <input
                              className="form-check-input"
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              onChange={toggleHandler(item)}
                              checked={mcqInfo[item]} />
                           <label className="form-check-label checkbox-label" htmlFor={`custom-checkbox-${index}`}>
                              {item}

                           </label>
                        </div>
                     </div>
                  )
               })
            }
         </div>
      )

   }
   // const renderMcq = () => {
   //    return (
   //       <div>
   //          {
   //             questions[currentQues].options &&
   //             Object.values(questions[currentQues].options).map((item, index) => {
   //                return (
   //                   <div>
   //                      <div className="form-check formcheckmcq checkbox-label">
                        
   //                         <input
   //                            // className={mcqInfo.includes(item)?"form-check-input":''}
   //                            className='form-check-input'
   //                            type='checkbox'
   //                            id={`custom-checkbox-${item}`}
   //                            onChange={toggleHandler(item)}
   //                            // checked={mcqInfo.includes(item)}
   //                            // defaultChecked={mcqInfo.includes(item)?true:false}
                          
   //                            />
   //                             {/* <input 
   //                             className='form-check-input'
   //                             type="checkbox" 
   //                             id={item} 
   //                             checked={mcqInfo} 
   //                             onChange={()=>toggleHandler(item)}/> */}
   //                         <label className="form-check-label checkbox-label" htmlFor={`custom-checkbox-${item}`}>
   //                            {item}

   //                         </label>
   //                      </div>
                       
   //                   </div>
   //                )
   //             })
   //          }
            
   //       </div>
   //    )

   // }
   const renderSingleMcq = () => {
      return (
         <div>
            {
               questions[currentQues].options &&
               Object.values(questions[currentQues].options).map((item, index) => {
                  return (
                     <div>
                        <button
                           key={item}
                           className={activeSingleQues === item ? 'btn selectedBtn' : 'btn notselectedBtn'}
                           disabled={selected}
                           onClick={() => handleClick(item)}
                        >
                           {item}

                        </button>
                     </div>
                  )
               })
            }
         </div>
      )
   }
   const renderFillInTheBlanks = () => {
      return (
         <input
            type="text"
            className='w-50 form-control  my-5'
            placeholder='Enter Your Ans'
            value={fillinBlanks}
            onChange={(e) => {
               handleFillInBlanks(e.target.value)
            }} />
      )
   }
   const rendetTrueFlase = () => {
      return (<div><div className='w-50 my-5'>
         <p>Choose One Option</p>
         <div>
            <div>
               <input
                  type="radio"
                  value={"true"}
                  checked={selectedOption === "true"}
                  onChange={handleChangeTf}
               /> True
            </div>
            <div>
               <input
                  type="radio"
                  value={"false"}
                  checked={selectedOption === "false"}
                  onChange={handleChangeTf}
               /> Flase
            </div>
         </div>
      </div>
      </div>
      )
   }
   const renderMatchTheFollowing = () => {
      return (
         <div className='row'>
            <div className='col d-flex'>
               <div >
                  {
                     Object.values(listOne) &&
                     Object.values(listOne).map((item, i) => {
                        return (
                           <><div className='fillbtnone'>
                              {item}
                           </div>
                           </>
                        )
                     })
                  }
               </div>
               <div>
                  {
                     Object.values(list) &&
                     Object.values(list).map((item, index) => (
                        <div className='fillbtn'
                           onDragStart={(e) => dragStart(e, index)}
                           onDragEnter={(e) => dragEnter(e, index)}
                           onDragEnd={drop}
                           key={index}
                           draggable>
                           {item}
                           <i class="fa-solid fa-grip-vertical text-right"></i>
                        </div>
                     ))}  </div>
            </div>
         </div>
      )
   }
  

   return (
      <div>
           <CountdownTimer time={totaltime} autoSubmitData={handleSubmit} />
         <div className='container-fluid'>
            <div className='row'>
               {/* {
                  countNext === 1 ? '' :
                     <div className='col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12'>
                        <div className='mt-5'>
                           {
                              data.map((item) => {
                                 
                                 
                                 return (
                                    <button
                                    className={green.includes(item.value)?'active_Index':'inactive_index'}
                                       onClick={() => hansleIndex(item)}>
                                       <span>{item.label}</span>
                                    </button>
                                 )
                              })
                           }
                        </div>
                     </div>
               } */}


               <div className='col-xl-10 col-lg-9 col-md-8 col-sm-12 col-12'>
                  {
                     countNext === 1 ? 'Submit Your Exam' :
                        <>
                           <div className='row my-5 justify-content-between '>
                              <div className='col px-0 Questionrow'>
                                 <div className='Questionhead Questionrow'>
                                    <h3>Question No  - {currentQues + 1} / {questions.length}</h3>
                                 </div>
                                 {/* <p className='text-sm type d-inline-block ms-3'>
                                    Question Type :  {questions && questions[currentQues].questionType}
                                 </p> */}
                              </div>

                           </div>
                           <div className='row justify-content-between'>
                              <div className='col '>
                                 <div className='Quesbody'>
                                    {/* <img src={iconQ} /> */}
                              
                                    <h5>
                                       Q. {questions && questions[currentQues].question}
                                    </h5>

                                 </div>

                              </div>
                           </div></>
                  }
                  <div className='row justify-content-between'>

                     <div className='col '>
                        {
                           countNext === 1 ? 
                           '' 
                           : 
                           questions && questions[currentQues].questionType === "Multiple select MCQ" ?
                           storeMcqInfo.length===0?
                           <div className='option_style'>
                                 <p> Select [ One / More than One ]  Options </p>
                                 {renderMcq()}
                              </div> 
                          
                              :
                              <>
                               <div className='ms-3'>
                           <h4>Selected</h4>
                           {
                              Object.values(storeMcqInfo).map((item)=>{
                                 return(<div className='selectedAnswer'><i className="fa-solid fa-square-check me-2"></i>{item}</div>)
                              })
                           }
                           <button className='btn lmsbtn' onClick={()=>setStoreMcqInfo([])}>Do you want to Change Your Answer?</button>
                        </div></>
                              : ''
                        }
                          {/* {
                           countNext === 1 ? '' : questions && questions[currentQues].questionType === "Multiple select MCQ" ?
                              <div className='option_style'>
                                 <p> Select [ One / More than One ]  Options </p>
                                 {renderMcq()}

                              </div> : ''
                        } */}
                        {
                           countNext === 1 ? '' : questions[currentQues].questionType === "Single select MCQ" ?
                              <div className='option_style'>
                                 <p> Select [ One ]  Options </p>
                                 {renderSingleMcq()}
                              </div> : ''
                        }
                        {
                           countNext === 1 ? '' : questions[currentQues].questionType === "Fill in the blanks" ?
                              <div className='option_style'>
                                 <p>Enter answer in Input Box</p>
                                 {renderFillInTheBlanks()}
                              </div> : ''
                        }
                        {
                           countNext === 1 ? '' : questions[currentQues].questionType === "True and False" ?
                              <div>
                                 {rendetTrueFlase()}
                              </div>
                              : ''
                        }
                        {
                           countNext === 1 ? '' : questions && questions[currentQues].questionType === "Match the following" ?
                              <div className='option_style'>
                                 <p>Drag and drop correct match from right hand side</p>
                                 {renderMatchTheFollowing()}
                              </div> : ''
                        }

                        <div className='ms-3 qbtn_sec'>

                           {/* {currentQues + 1 !== 1 ?
                           <button className='btn lmsbtn Qlmsbtn me-3' onClick={() => handlePrevious()}>Prevoius</button>
                              : ''
                           } */}

                           {
                              countNext === 1 ?
                                 <button
                                    type="button"
                                    className='btn lmsbtn Qlmsbtn'
                                    onClick={handleSubmit}>Submit</button>
                                 :
                                 <>
                                    <button className='btn lmsbtn Qlmsbtn me-3' onClick={() => handleSkip()}>Skip</button>
                                    <button className='btn lmsbtn Qlmsbtn' onClick={() => handleNext()}>{pushSpinner?<i class="fas fa-spinner fa-spin"></i>:'' }Next</button>
                                 </>

                           }


                        </div>

                        {
                           showSuccessMsg ?
                              <SuccessMsg
                                 msg={successMsg}
                                 setTrigger={setShowSuccessMsg}
                                 trigger={triggerSuccess}
                              />
                              : ''
                        }
                        {showPopup ?
                           <ErrorMsg
                              errorStatus={errorObj}
                              setTrigger={setShowpopup}
                              trigger={popup}
                           /> : ''}

                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div>
            <div>
            </div>
         </div>
      </div>
   )
}