import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Banner from "../../DefaultComponent/appComponent/Banner";
import ExamItem from "./ExamItem";
import Loader from "../../DefaultComponent/Loader";
import bannerImg from "../../../template/img/Exam.png";
import { Button } from "react-bootstrap";
import TermsAndConditionsModal from "./TermsAndConditions";
import Quiz from "./Quiz";
import StatusModal from "../../DefaultComponent/appComponent/StatusModal";
import { useKeycloak } from "@react-keycloak/web";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import { filtergetByUserIdUnit, filtergetByUserIdExam } from "../../../services/ApiCaller/content/unit";
// import bannerImg from './../../../template/img/certificate_banner.png';

const OnlineExam = () => {
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [showModal, setModalShow] = useState(false);
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const { keycloak } = useKeycloak();
  const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed ? false : true
  );
  const [lastInteractedCourse, setLastInteractedCourse] = useState(null);
  const [userData, setUserData] = useState(null);
  const [courses, setCourses] = useState([]);


  //######################################## START OF USE EFFECTS #####################################//

  useEffect(() => {
    fetchUrl();
    fetchUserInfo();
  }, []);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };


  //######################################## END OF USE EFFECTS #####################################//

  //######################################## START OF NETWORK CALLS #####################################//
  const fetchUserInfo = () => {
    let filterdata =
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.sub;
    const response = getUserByUserId(filterdata);
    response.then(
      (success) => {
        setUserData(success);
        fetchCourses(success);
      },
      (error) => {}
    );
  };
  const fetchCourses = (uData) => {
    setLoading(true);
    // const response = filterUnit(Data)
    const response = filtergetByUserIdExam(uData.id);
    response.then(
      (success) => {
        setLoading(false);
        setCourses(success);
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };
  

  //######################################## END OF NETWORK CALLS #####################################//

  const onCourseInteraction = (item) => {
    setLastInteractedCourse(item)
    setModalShow(true)
  };

  return (
    <React.Fragment>
      {/* <Header menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse} />
            <div className=" app">
                <Sidebar url={currentUrl} menuCollapse={menuCollapse} />
                <main>
                    {!loading ?
                        <>
                            <div className='mainpage' >
                                <div class="page_main"> */}
      <div class="page_inner exam">
        <Banner img={bannerImg} styleCss={"cetf_banner"} />
        <div className="container-fluid">
          <div className="row">
            {courses.map((item, index) => {
              return (!courses.examPresent ? <ExamItem selectExamType={onCourseInteraction} courseData={item} /> : " ");
            })}
          </div>
        </div>
      </div>

      {/* </div>


                            </div>
                        </>
                        :
                        <Loader />
                    }

                    <Quiz
                        show={showModal}
                        backdrop={'static'}
                        onHide={() => {
                            setModalShow(false)}}
                    />
                </main>
                
            </div>
 */}
      {/* {showModal && (
        <Quiz
          show={showModal}
          backdrop={"static"}
          onHide={() => {
            setModalShow(false);
          }}
        />
      )} */}
     {(lastInteractedCourse && showModal) && <Quiz
        show={showModal}
        backdrop={"static"}
        onHide={() => {
          setModalShow(false);
        }}
        courseid={lastInteractedCourse.id}
        lessonid={0}
        userData={userData}
        FROM={"EXAM"}
      />}
    </React.Fragment>
  );
};

export default OnlineExam;
