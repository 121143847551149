
import axios from '../../axiosconfig';

// lesson Craetion
export function lessonCreation(data) {
   return  new Promise((resolve,reject)=>{ 
    axios.post('content/api/v1/lms-content/lesson/create',data,{
      headers: {
          //'Access-Control-Allow-Origin':'*',
          "Accept": "application/json",
          "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
    });
}

//filter lesson
export function filterLesson(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('content/api/v1/lms-content/lesson/filter',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}
// http://164.52.204.237:10003/api/v1/lms-content/lesson/getByStatuses/1
//filter lesson by status
// export function filterLessonByStatus(id,data) {
//     return  new Promise((resolve,reject)=>{ 
//      axios.post(`content/api/v1/lms-content/lesson/getByStatuses/${id}`,data,{
//        headers: {
//            //'Access-Control-Allow-Origin':'*',
//            "Accept": "application/json",
//            "Content-Type": "application/json"
//        }
//      })
//        .then(function (response) {
//          resolve(response.data);
//        })
//        .catch(function (error) {
//          reject(error);
//        });
//      });
//   }


// http://164.52.204.237:10003/api/v1/lms-content/lesson/student/getByUnit/1
export function filterLessonByStatus(id,data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`content/api/v1/lms-content/lesson/student/getByUnit/${id}`,data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}


// http://164.52.204.237:10003/api/v1/lms-content/course/getById/1
export function filterCourseByStatus(id,data) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`content/api/v1/lms-content/course/student/getById/${id}`,data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}


//fetch lesson List
export function lessonList() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('content/api/v1/lms-content/lesson/getAll')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}

  //get lesson in Id
  export function lessonbyId(inId) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`content/api/v1/lms-content/lesson/getById/${inId}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

      //lesson delete 
      export function lessonDelete(inId) {
        return  new Promise((resolve,reject)=>{ 
         axios.delete(`content/api/v1/lms-content/lesson/delete/${inId}`)
           .then(function (response) {
             resolve(response.data);
           })
           .catch(function (error) {
             reject(error);
           });
         });
      }


     //lesson Edit
export function lessonEdit(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.put('content/api/v1/lms-content/lesson/edit',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }


//lesson Send for approval
export function lessonSendForApproval(id,userid) {
    return  new Promise((resolve,reject)=>{ 
     axios.put(`content/api/v1/lms-content/lesson/sendForApproval/${id}/${userid}`,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }


//lesson Validate
export function lessonValidate(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.put('content/api/v1/lms-content/lesson/validate',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  
  }


//toggle lesson by id
export function toggleLessonById(intId) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`content/api/v1/lms-content/lesson/toggleStatus/${intId}`,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}

//toggle lesson by id
export function fetchModuleData(intId) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`content/api/v1/lms-content/lesson/student/getById/${intId}`,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}
