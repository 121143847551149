
import axios from '../../../axiosconfig';
export function beneficiaryReport(id) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`assessment/api/v1/lms_assessment/data/mgmt/beneficiary/report/${id}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
         // console.log('Error on Authentication');
       });
     });
  }