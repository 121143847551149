
import axios from '../../axiosconfig';

// interventions update
export function interventionsupdate(data) {
   return  new Promise((resolve,reject)=>{ 
    axios.post('admin/api/v1/lms-admin/interventions/update',data,{
      headers: {
          //'Access-Control-Allow-Origin':'*',
          "Accept": "application/json",
          "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
        // console.log('Error on Authentication');
      });
    });
}
export function interventionscreate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/interventions/create',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}

export function programHeadactivate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/interventions/programHead/activate',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}
export function programHeadmap(id,data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`admin/api/v1/lms-admin/interventions/programHead/${id}/map`,data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}
export function programHeadCreate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/interventions/programHead/create',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}
export function TrainerCreate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/trainer/create',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}

export function trainerEdit(data,userId) {
  return  new Promise((resolve,reject)=>{ 
   axios.put(`admin/api/v1/lms-admin/trainer/updateByUserId/${userId}`,data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}

export function beneficiaryCreate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/beneficiary/create',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}
export function beneficiaryEdit(userId,data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`admin/api/v1/lms-admin/beneficiary/updateByUserId/${userId}`,data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });

}

export function interventionsactivate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/interventions/activate',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
export function interventionBasedOnId(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/interventions/${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
export function interventionsview() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('admin/api/v1/lms-admin/interventions/view/ui')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       
       // console.log('Error on Authentication');
     });
   });
}


export function fetchInterventionLabel(interId) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/interventions/intervention-level/byInterventionId/${interId}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}  
export function programHeadview() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('admin/api/v1/lms-admin/interventions/programHead/view')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       
       // console.log('Error on Authentication');
     });
   });
}

export function interventionsdelete(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/interventions/delete/${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
export function trainerList() {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/trainer/list`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

export function trainerBasedOnIntervention(intId,schId) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/trainer/list/${intId}/${schId}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}