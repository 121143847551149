import React, { useEffect } from 'react'
import { ENV } from '../../../util/env'

export default function ScromViewer({ fileId }) {
  console.log("as props", fileId)
  useEffect(() => {

  }, [])
  return (

    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card-body frame">
              <iframe
                // src="http://13.233.166.196/Files"
                src={ENV.SCROM_URL + fileId}
                // src={ENV.SCROM_URL}
                // src={fileId}
                frameborder="0"
                allow="autoplay"
              >
              </iframe>
            </div>
          </div>


        </div>
      </div>
    </>

  )
}
