import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Loader from "../../DefaultComponent/Loader";
import ModuleItem from "./ModuleItem";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentAccordian from "./ContentAccordian";
import Accordion from "react-bootstrap/Accordion";
import { dammyData } from "../../../util/videoData";
import axios from "axios";
import ManageQuiz from "./lessons/ManageQuiz";
import Quiz from "./../OnlineExam/Quiz";
import { useKeycloak } from "@react-keycloak/web";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import { Assessmenttime } from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
import { AssessmentDataByAssessmentId } from "../../../services/ApiCaller/assessment/AssessmentManagement/AssessmentDataManagementController";
import { ASSESSMENT } from "../../../util/Constants";
import contentcomingsoon from "../../../template/img/contentcomingsoon.png";
import ContentViewModal from "./lessons/ContentViewModal";
import { fetchModuleData, filterCourseByStatus, filterLessonByStatus } from "../../../services/ApiCaller/content/lesson";
import Comingsoon from "../../DefaultComponent/appComponent/ComingSoon";
import ContentActivity from "./ContentActivity";

import { leaderDownloadfile } from '../../../services/ApiCaller/file/fileManagement';

const ModuleContentView = () => {

  const [show, setShow] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const { courseId, lessonId } = useParams();

  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed ? false : true
  );
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("");
  const [listOfLessons, setLessons] = useState([]);
  const [showModal, setModalShow] = useState(false);
  const { keycloak } = useKeycloak();
  const [userData, setUserData] = useState(null);
  const [courseDetails, setcourseDetails] = useState(null);
  const [assessments, setAsssessments] = useState([]);
  const [moduleDetails, setModuleDetails] = useState(null)

  const manageRedirection = () => {
    // navigate('');
  };

  //#################################################### START OF USE EFFECTS #########################################//

  useEffect(() => {
    fetchUrl();
    console.log("query params", courseId, lessonId);
    fetchModerateUserList();
    fetchAssessmentDetails();
    contentFilter()
    fetchModuleDetails()
    fetchcourseName()
  }, []);

  

  //#################################################### END OF USE EFFECTS #########################################//

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
    console.log(urlElements, "urlElements");
  };

  const fetchAssessmentDetails = () => {
    setLoading(true);
    const resp = AssessmentDataByAssessmentId(courseId, lessonId, ASSESSMENT);
    resp.then(
      (success) => {
        setLoading(false);
        if (success.length > 0) {
          console.log("ASSESSMENT DETAILS SUCCESS", success)
          setAsssessments(success)
        } else {
        }
      },
      (error) => { 
        console.log("ASSESSMENT DETAILS ERROR", error)
        setLoading(false)}
    );
  };

  const fetchModuleDetails = () => {
    setLoading(true);
    const resp = fetchModuleData(lessonId);
    resp.then(
      (success) => {
        setLoading(false);
        // if (success.length > 0) {
          console.log("ASSESSMENT DETAILS SUCCESS", success)
          setModuleDetails(success)
        // } else {
        // }
      },
      (error) => { 
        console.log("ASSESSMENT DETAILS ERROR", error)
        setLoading(false)}
    );
  };

  const fetchModerateUserList = () => {
    let filterdata =
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.sub;
      setLoading(true)
    const response = getUserByUserId(filterdata);
    response.then(
      (success) => {
        setLoading(false)
        setUserData(success);
        // fetchAssessmentTime(courseId);
      },
      (error) => {
        setLoading(false)
      }
    );
  };


  const fetchcourseName = () => {
    //ONLY APPROVED
    setLoading(true)
    const response = filterCourseByStatus(courseId, ["Approved"]);
    response.then(
      (success) => {
        setLoading(false);
        setcourseDetails(success);
        console.log("COURSE DETAILS", success)
        //  setlessonId(success.unit.id);
        // console.log("idddd", success.unit.id)
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };

  const contentFilter = (data) => {
    setLoading(true)
    return new Promise((resolve, reject) => {
      // http://164.52.204.237:10003/api/v1/lms-content/content/student/getByLesson/1
      axios
        .post(
          `content/api/v1/lms-content/content/student/getByLesson/${lessonId}`
        )
        .then(function (response) {
          setLoading(false)
          setLessons(response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          setLoading(false)
          console.log("Content Filter Error");
          reject(error);
        });
    });
  };

  const onQuizStartClick = () => {
    setModalShow(true);
  };

  const onContentItemClick = (lessionItem) => {
    console.log(lessionItem)
    setSelectedContent(lessionItem);
    setShow(true)
  }

  // Download activity
//   const contentDownload = (certificateId,fileName,fileType) => {
// debugger;
//     const response = leaderDownloadfile(certificateId)
//     .then(function (response) {
//       let base64Res = removeBase64Prefix(response.data)
//       downloadPdfFromBase64(base64Res, (fileType.includes('ActivityDoc')? fileName+'.docx' : fileName+'.xlsx'));
//     })
//      .catch(function (error) {
//         console.log("Issue in downloading certificate", )
//      });
// }


const contentDownload = (certificateId,fileName,fileType) => {
  console.log('fileType', fileType);
      const response = leaderDownloadfile(certificateId)
      .then(function (response) {
        if(fileType ==='ActivityDoc'){
          let base64Res = removeBase64Prefix(response.data)
          downloadPdfFromBase64(base64Res, fileName+'.docx');
        }else{
          let base64Res = removeBase64PrefixForExcel(response.data)
          downloadXlsxFromBase64(base64Res, fileName+'.xlsx');
        }
      })
       .catch(function (error) {
          console.log("Issue in downloading certificate", )
       });
  }

function removeBase64Prefix(base64String) {
  const prefix = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';
  if (base64String.startsWith(prefix)) {
    return base64String.slice(prefix.length);
  } else {
    return base64String;
  }
}

function downloadPdfFromBase64(base64String, fileName) {
  const binaryData = atob(base64String);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  if(fileName.includes('.docx')){
    const blob = new Blob([arrayBuffer], {type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName || 'DownloadedFile.docx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  }else{
    const blob = new Blob([arrayBuffer], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName || 'DownloadedFile.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  }
  
}


function removeBase64PrefixForExcel(base64String) {
  const prefix = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
  if (base64String.startsWith(prefix)) {
    return base64String.slice(prefix.length);
  } else {
    return base64String;
  }
}

function downloadXlsxFromBase64(base64String, fileName) {
  console.log('fileName ', fileName)
  const binaryData = atob(base64String);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName || 'DownloadedFile.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//content filter
const contentOpen = (data) => {
  console.log('data', data.id)
  return new Promise((resolve, reject) => {
    axios.get(`content/api/v1/lms-content/content/student/getById/${data.id}`,
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log("Content Filter Error",)
        reject(error);
      });
  });
}
  
  const onClickActivityDownload = (lessionItem) => {
    contentDownload(lessionItem.fileId, lessionItem.name, lessionItem.type);
    contentOpen(lessionItem)

    console.log('lessionItem', lessionItem)
  }

  const onModalDissmiss = () => {
    setShow(false)
  }

  return (
    <React.Fragment>

      {showModal && (
        <Quiz
          ExamFlag={true}
          show={showModal}
          backdrop={"static"}
          onHide={() => {
            setModalShow(false);
          }}
          courseid={courseId}
          lessonid={lessonId}
          userData={userData}
          // quizTotalDuration={quizTotalDuration}
          FROM={"QUIZ"}
        />
      )}

      <div className="module_content_view_main">
        <div className="model_content_view_header">
          <div className="row">
            <div className="col-lg-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              {moduleDetails && (
                <ModuleItem
                  module="5"
                  moduleName="Lorem ipsum dolor sit amet"
                  currentStatus="0"
                  manageRedirection={manageRedirection}
                  moduleRedirectionButton={false}
                  coursedata={moduleDetails}
                  index={0}
                />
              )}
            </div>
            {/* <div className="col-lg-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="next_btn_area">
                {currentIndex + 1 < modules.length && (
                  <button className="next_btn" onClick={handleNextClick}>
                    Next
                  </button>
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className="module_content_sec">
          <div className="row">
            <div className="col-12">
              <div className="module_content_main">
                <div className="module_sec_title">
                  <h5>Module Lessons</h5>
                </div>
                <div className="accordian_content">
                  <Accordion
                    onSelect={(e) => {
                      console.log("EVENT", e);
                      setActiveKey(e);
                      if (e !== null) {
                        // leaderDownloadfile(e)
                      }
                    }}
                  >
                    {/* {!loading && listOfLessons.length === 0 ? (
                      <Comingsoon></Comingsoon>
                    ) : (
                      listOfLessons.map((item, index) => {
                        return item.type != "scorm" ? (
                          <ContentAccordian
                            lessionItem={item}
                            index={index}
                            activeKey={activeKey}
                            onContentItemClick = {onContentItemClick}
                            
                          />
                        ) : (
                          <></>
                        );
                      })
                    )} */}
                    {(!loading && listOfLessons.length === 0) &&
                      <Comingsoon src={contentcomingsoon}></Comingsoon>
                    }
                    { listOfLessons.length > 0 &&
                      listOfLessons.map((item, index) => {
                        
                        console.log('item_type', item.type)

                          return !item.type.includes('Activity') ? (
                          <ContentAccordian
                            lessionItem={item}
                            index={index}
                            activeKey={activeKey}
                            onContentItemClick = {onContentItemClick}
                          />
                        ) : (
                          <ContentActivity lessionItem={item}
                            index={index}
                            activeKey={activeKey}
                            onClickActivityDownload = {onClickActivityDownload}/>
                        );
                        
                        
                      })
                    }
                  </Accordion>

                    {/* Disable quiz */}
                    {/* Uncomment */}
                  {(moduleDetails?.completedPercentage === 100 &&
                    assessments.length > 0) && (
                      <div className="quizStartBtn">
                        <ManageQuiz
                          quizTitle="Start Quiz"
                          onQuizStartClick={onQuizStartClick}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
                        </div>
                    </div> */}
      {/* </>
                    :
                    <Loader />
                  }
                </main> */}
      {/* </div> */}

         {show &&  <ContentViewModal show={show} onModalDissmiss = {onModalDissmiss} selectedContent={selectedContent}/>}       
    </React.Fragment>
  );
};

export default ModuleContentView;
