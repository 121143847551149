import { useEffect, useState } from 'react';
import Loader from '../../DefaultComponent/Loader';
import Header from "../../layout/Header";
import '../../layout/layout.scss';
import Sidebar from '../../layout/Sidebar';
import FullCalendar from "../schedulerview/calendar";
export default function Calendarview() {
    const [loading, setLoading] = useState(false)
    const [currentUrl, setCurrentUrl] = useState("")

    const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
    const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);

    useEffect(() => {
      fetchUrl();
    }, [])
    const fetchUrl = () => {
      let urlElements = window.location.href.split('/');
      let urlElelement = (urlElements[3])
      setCurrentUrl(`/${urlElelement}`)
    }
    return (
        <>
            <Header menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}/>
      <div className=" app">
        {/* Sidebar */}
        <Sidebar url={currentUrl} menuCollapse={menuCollapse}/>
                {/* main comp */}
                <main >
                {!loading ?
                <>
                    {/* page title */}
                    <div className='row pageheader justify-content-between'>
                        <div className='col'>
                            <div className='pagetitle'>
                               Calendar
                            </div>
                        </div>
                        {/* <div className='col d-flex justify-content-end'> <button
                    type="button"
                    className="btn lmsbtn btn-sm"
                    onClick={() => setInterventionsShow(true)}
                  >
                    Add Intervention
                  </button>
                  </div> */}
                    </div>
                    {/* main page  */}
                    <div className='mainpage' > 
                    <FullCalendar/>
                    </div>
                    </>
                    :
                    <Loader />
                  }
                </main>
            </div>
        </>
    )
}
