import { useKeycloak } from '@react-keycloak/web';
import { useContext, useEffect } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import logo from "../../template/img/Group 1105.svg";
import logo from "../../template/img/brandLogo.png";
import styles from './DefaultComponent.module.scss';
import { GetSvgIcon } from '../../util/SvgIcons';
import { center } from 'react-dom-factories';
import * as Actions from '../../app/state/Actions';


export default function Header(props) {
  // import { isAdmin } from '../misc/Helpers'
  // const {menuCollapse, setMenuCollapse} = props
  const navigate = useNavigate()
  const { keycloak } = useKeycloak();
  const {state, dispatch} = Actions.getStateDispatch(useContext)

  const handleLogInOut = () => {
    if (keycloak.authenticated) {
      // props.history.push('/')
      navigate("/")
      keycloak.logout()
      localStorage.removeItem("authenticate")
      localStorage.removeItem("jwt-secret")

    } else {
      keycloak.login()
    }
  }
  useEffect(() => {
    getresource_access()
  }, [])
  const checkAuthenticated = () => {
    if (!keycloak.authenticated) {
      handleLogInOut()
    }
  }
  const getUserresource = () => {
    return keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.resource_access
  }

  const getUsername = () => {
    // console.log("nnn", keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.resource_access)
    return keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.name
  }
  const getresource_access = () => {

    const resourceaccessname = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.resource_access

    // if (!resourceaccessname.lms_beneficiary_portal) {
    //   navigate("/")
    //   keycloak.logout()
    //  localStorage.removeItem("authenticate")
    //   localStorage.removeItem("jwt-secret")
    // }


  }


  const openSidebar = () => {
    console.log(localStorage.getItem("sidebar-collapsed", false))
    // localStorage.getItem("sidebar-collapsed");
    if(localStorage.getItem("sidebar-collapsed", false)){
      // setSidebarCollapsed(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }
    // setSidebarCollapsed(true);
      localStorage.setItem("sidebar-collapsed", true);
  
    };

  const getLogInOutText = () => {
    return keycloak.authenticated ? "Logout" : "Login"
  }

  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    // menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
      dispatch(Actions.stateChange("appReducer", "sidebarCollapse", !state.appReducer.sidebarCollapse))
      localStorage.setItem("sidebar-collapsed", !state.appReducer.sidebarCollapse);
  };

  return (

    <>

      <nav className={`navbar navbar-expand-sm navbar-light ${styles.Header}`}>

        <div className="container-fluid header_container_fluid" style={{marginLeft: 10, marginRight: 10, marginTop: 0, marginBottom: 0}}>
        <span className='bar'> <i class="fa-solid fa-bars" onClick={() => {menuIconClick()}}></i> </span>
          <a className="navbar-brand brand_area" href="/" >
            <img src={logo} className="img-fluid logo-img" /></a>
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">

              </li>

            </ul>
            <span className="navbar-text navbartext" style={{justifyContent: 'center', alignItems: 'center', marginRight: 10, cursor: 'pointer' }}>
              {/* <span className={`user-name`}>{getUsername()}</span> */}
              <span style={{marginLeft: 10, marginRight: 10}}>{<GetSvgIcon iconName={"profile"} color={"black"}/>}</span>
              {/* <button className={`btn  ${styles.profilebtn} profile-btn`} type="button ">   <i className="fa fa-user" aria-hidden="true"></i>
              </button> */}
              {/* <i class="fa-solid fa-right-from-bracket"></i> */}
              <i className="fa-solid fa-right-from-bracket fa-lg" aria-hidden="true" style={{marginLeft: 20, marginRight: 5, transform: 'rotate(180deg)', color: 'black' }} onClick={handleLogInOut}></i>
              {/* <DropdownButton id="dropdown-basic-button " className={`d-inline-block logout-btn`} title={<i className="fa fa-cog" aria-hidden="true"></i>}>
                <Dropdown.Item className={``} type="button " onClick={handleLogInOut}> <i className="fa fa-sign-out mr-1" aria-hidden="true"></i> {getLogInOutText()} </Dropdown.Item>
              </DropdownButton> */}
            </span>
          </div>
        </div>
      </nav>
    </>
  )
}
