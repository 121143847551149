// const NoMatch = () => (

//   <h1 className="text-info"></h1>
// )

import React from 'react';
import './../../App.scss';
import './nomatch.css';


export default function NoMatch() {
	return (
		<>
			{/* <Dimmer inverted  page>
      <Header style={{ color: '#4d4d4d' }} as='h2' icon inverted>
        <Icon  name='cog' />
        <Header.Content>Keycloak is loading
          <Header.Subheader style={{ color: '#4d4d4d' }}>or running authorization code flow with PKCE</Header.Subheader>
        </Header.Content>
      </Header>
    </Dimmer> */}

			<div className="page_404">
				<div className="container">
					<div className="row">
						<div className="col-sm-12 ">
							<div className="col-sm-12  text-center">
								<div className="four_zero_four_bg">

								</div>

								<div className="contant_box_404">
									<h3 className="h2">
										Look like you're lost
									</h3>

									<p>the page you are looking for not avaible!</p>

									<a href="/" className="link_404">Go to Home</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}