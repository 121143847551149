import { useEffect, useState } from "react";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import { useKeycloak } from "@react-keycloak/web";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { userLists } from "../../../services/ApiCaller/user/usercontroller";
import { AssessmentDataFilter } from "../../../services/ApiCaller/assessment/AssessmentManagement/AssessmentDataManagementController";
import { MarksForIndividualStudent } from "../../../services/ApiCaller/assessment/AssessmentAttempt/AssessmentAttemptController";

export default function ViewReportCard() {
  const {
    formState: { errors },
    control,
  } = useForm();

  //Keycloak
  const { keycloak } = useKeycloak();

  let { id } = useParams();

  //Loader
  const [loading, setLoading] = useState(false);

  //Sidenav Highlight
  const [currentUrl, setCurrentUrl] = useState("");

  //SHOW ERROR MSGS
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [errorObj, setErrorObj] = useState({});

  //SHOW SUCCESS MSGS
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);

  //Fetch List from API
  const [data, setData] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [lesson, setLesson] = useState([]);
  const [unit, setUnit] = useState([]);
  const [intervention, setIntervention] = useState([]);
  const [batch, setBatch] = useState([]);
  const [school, setSchool] = useState([]);
  const [user, setUser] = useState([]);

  //User ID
  const [userid, setUserid] = useState("");

  //Assessment
  const [allAssessment, setAllAssessment] = useState([]);
  const [assessmentId, setAssessmentId] = useState([]);

  useEffect(() => {
    fetchUrl();
    fetchuserList();
    fetchList();
    fetchAssessment();
  }, []);

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };

  const fetchAssessment = (intLabelId) => {
    setAllAssessment([])
    const Data = JSON.stringify({
      interventionLevelId: intLabelId,
      statuses: ["Approved"],
    });
    const response = AssessmentDataFilter(Data);
    response.then((success) => {
      if (success.length === 0) {
      } else {
        Object.values(success).map((item) => {
          let single = {
            value: item.id,
            label: item.name,
            ass_mode: item.mode,
            ass_type: item.type
          };
          setAllAssessment((prevdata) => [...prevdata, single]);
        });
      }

    })

  }

  //VIEW USER
  const fetchuserList = () => {
    let filterdata =
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.sub;
    const response = userLists(filterdata);
    response.then(
      (success) => {
        console.log("success", success);
        //   Object.values(success).map((item) => {
        // if(keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.email === item.email){
        //   fetchAssessment(item.id)
        fetchList(success.id);

        // }
        //   });
      },
      (error) => {
        // setLoading(false)
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };

  const fetchList = (userid) => {
    // const Data = JSON.stringify({
    //   assessmentId: 1,
    //   userId: 1,
    // });
    console.log("userid", userid);
    const response = MarksForIndividualStudent(userid);
    response.then(
      (success) => {
        setLoading(false);
        setData(success);
        setAssessment(success.scheduleAssessment.assessment);
        setLesson(success.scheduleAssessment.assessment.lesson);
        setUnit(success.scheduleAssessment.assessment.lesson.unit);
        setIntervention(
          success.scheduleAssessment.assessment.lesson.unit.intervention
        );
        setBatch(success.scheduleAssessment.batches);
        setSchool(success.scheduleAssessment.batches.schoolDTO);
        setUser(success.user);
      },
      (error) => {
        setLoading(false);
        setErrorObj(error);
        setShowpopup(true);
        setPopup(true);
        setData([]);
      }
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "",
      backgroundColor: state.isSelected ? "#003366" : "",
      padding: 20,
    }),
    control: (base, state) => ({
      ...base,
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1px solid black",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "1px solid black",
        borderRadius: "0",
      },
    }),
  };

  return (
    <>
      <Header />
      <div className={loading ? "app backwhite" : "app"}>
        {/* Sidebar */}
        <Sidebar url={currentUrl} />
        {/* main comp */}
        {!loading ? (
          <>
            <main>
              {/* page title */}
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">View Report Card</div>
                </div>
              </div>
              {/* main page  */}
              <div className="mainpage">
                <div className="App">
                  <div calssName="card">
                    <div calssName="card-body">
                      <div className="row justify-content-center ">
                        {/* INTERVENTION DROPDOWN */}
                        <div className="form-group  formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                            <label htmlFor="idRole" className="labelheading">
                              Select Assessment
                            </label>
                            {/* role */}
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                const currentSelection = allAssessment.find(
                                  (c) => c.value === value
                                );
                                const handleAssessment = (selectedOption) => {
                                  onChange(selectedOption?.value);
                                  let str;
                                  if (selectedOption != null) {
                                    setAssessmentId(selectedOption.value);
                                  } else {
                                    str = null;
                                    setAssessmentId(str);
                                  }
                                };
                                return (
                                  <Select
                                    isClearable
                                    styles={customStyles}
                                    className="Selectontrol"
                                    menuColor="red"
                                    inputRef={ref}
                                    value={currentSelection}
                                    name={name}
                                    options={allAssessment}
                                    onChange={handleAssessment}
                                  />
                                );
                              }}
                              name="Unit"
                            />
                            
                          </div>

                      </div>
                    </div>
                  </div>
                  <p className="pagetitle">Report Card</p>
                  <div className="container">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            Intervention ID : {intervention.interventionId}
                          </td>
                          <td>Batch Name : {batch.batchName}</td>
                          <td>Batch ID : {batch.id}</td>
                        </tr>
                        <tr>
                          <td>Student Name : {user.username}</td>
                          <td>Institute Name : {school.schoolName}</td>
                          <td>Academic Year : {batch.academicYear}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="pagetitle">Report Details</p>

                  <div className="container">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Intervention ID</td>
                          <td>{intervention.interventionId}</td>
                        </tr>
                        <tr>
                          <td>Intervention Name</td>
                          <td>{intervention.interventionName}</td>
                        </tr>
                        <tr>
                          <td>Unit Name</td>
                          <td>{unit.name}</td>
                        </tr>
                        <tr>
                          <td>Lesson Name</td>
                          <td>{lesson.name}</td>
                        </tr>
                        <tr>
                          <td>Assessment Name</td>
                          <td>{assessment.name}</td>
                        </tr>
                        <tr>
                          <td>Assessment Type</td>
                          <td>{assessment.type}</td>
                        </tr>
                        <tr>
                          <td>Academic Year</td>
                          <td>{batch.academicYear}</td>
                        </tr>
                        <tr>
                          <td>Batch Name</td>
                          <td>{batch.batchName}</td>
                        </tr>
                        {/* <tr>
                        <td>Assessment Date</td>
                        <td>{}</td>
                      </tr> */}
                        {/* <tr>
                        <td>Attendence</td>
                        <td>{}</td>
                      </tr> */}
                        <tr>
                          <td>Total Marks</td>
                          <td>{data.totalMarks}</td>
                        </tr>
                        {/* <tr>
                        <td>Obtained Marks</td>
                        <td>{}</td>
                      </tr> */}
                        <tr>
                          <td>Status</td>
                          <td>{data.status}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </main>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
