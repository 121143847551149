import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';


const Verifiedtyle = {
    height: '32px',
    width: '32px'
}

export default function SuccessMsg(props) {
    const [show, setShow] = useState(true);
    const [reject, setReject] = useState(false)
    const [approve, setApprove] = useState(false)


    return (props.trigger) ?
        (<div>
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Body >
                    <div className='text-center my-5'><h4>{props.error}</h4></div>
                    {
                        Object.values(props.msg).map((data, i) => {
                            let imgicon= data.icon.icons
                            console.log(imgicon)
                            return (
                                <div className='text-center'>
                                <div key={i}>
                                    {}
                                    <img  src={imgicon}/>
                                    <p>{data.msg}</p>
                                </div>
                                </div>
                                
                            )
                        })
                    }
                </Modal.Body>
                <Modal.Footer className='m-auto'>
                    <Button className="btn lmscanclebtn" 
                    onClick={() => {
                        props.setTrigger(false)
                        // window.location.reload()
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>) : ""
}




