import React from 'react';
import { useNavigate } from 'react-router-dom';


const CertificateList = (props) => {
    const navigate = useNavigate();
    
    const {cerImage, certificatedata} = props;
    return(
        <React.Fragment>
             <div className="certificate_list_inner">
                    <div className="row g-5">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="certificate_list_image">
                                <img src={cerImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="certificate_right_details">
                                <h4 className="certificate_title_details">{certificatedata.courseNam ? certificatedata.courseNam : "NA" }</h4>
                                <p className="certificate_module_details">{certificatedata.lessonCount ? certificatedata.lessonCount : "NA"} {certificatedata.lessonCount > 1 ? "Modules" : "Module"}</p>
                                <p className="certificate_holder_details">Congratulations  {certificatedata.userName ? certificatedata.userName : "NA"} </p>
                                <div className="cer_view_btn_area">
                                    <button className="cer_preview_btn" onClick={()=> navigate(`/Certificate/CertificateDownload/${certificatedata.cerFileId}`, {state: certificatedata})}><i className="fa-solid fa-magnifying-glass-plus me-2"></i>Preview</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    )
}

export default CertificateList;