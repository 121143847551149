import FileViewer from 'react-file-viewer';

export default function LoadContent(props) {
    const { fileType, filePath } = props;


    const onError = e => {
        console.log(e, "error in file-viewer");
    };
    return (

        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="card-body">
                            <div style={{ height: '100%' }} key={Math.random()}>
                                {/* if we want to load comp dynamically */}
                                <FileViewer
                                    fileType={fileType}
                                    filePath={filePath}
                                    onError={onError}
                                />

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
