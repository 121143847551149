export const ENV={
    NAME:process.env.REACT_APP_ENV,
    API_URL:process.env.REACT_APP_API_URL,
    API_KEY_URL:process.env.REACT_APP_KEY_URL,
    SCROM_URL:process.env.REACT_APP_SCROM_FILE_URL,
    HTML_URL:process.env.REACT_APP_HTML_FILE_URL,
    AUDIO_URL:process.env.REACT_APP_AUDIO_FILE_URL,
    NOTIFICATION_URL:process.env.REACT_APP_NOTIFICATION_URL
}

//if other
// export const otherEnvs={

// }