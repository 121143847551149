
import { CurrentTimeIndicator, ViewState } from '@devexpress/dx-react-scheduler';
import {
  Appointments, AppointmentTooltip, DateNavigator, DayView, MonthView, Scheduler, TodayButton, Toolbar,
  ViewSwitcher, WeekView
} from "@devexpress/dx-react-scheduler-material-ui";
import { Paper } from '@mui/material';
import { blue } from '@mui/material/colors';
// import  MuiThemeProvider  from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
// import { appointments } from './appointments';
import { getAllcalendarApis } from '../../../services/ApiCaller/calenddar/calenderapi';
import { getUserByUserId } from '../../../services/ApiCaller/user/usercontroller';
import './calender.scss';
import moment from "moment";
import DatePicker from "react-datepicker";

const PREFIX = 'Demo';
const classes = {
  icon: `${PREFIX}-icon`,
  textCenter: `${PREFIX}-textCenter`,
  firstRoom: `${PREFIX}-firstRoom`,
  secondRoom: `${PREFIX}-secondRoom`,
  thirdRoom: `${PREFIX}-thirdRoom`,
  header: `${PREFIX}-header`,
  commandButton: `${PREFIX}-commandButton`,
};
const theme = createTheme({ palette: { type: "light", primary: blue } });

function Calendar() {
  const [loading, setLoading] = useState(false);
  //confirmation
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  //SHOW ERROR MSGS
  const [errorMsg, setErrorMsg] = useState("");
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [month, setMonth] = useState(new Date());


  const [testList, setTestList] = useState([]);
  const [errorObj, setErrorObj] = useState({});
  //SHOW sUCCESS MSGS
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [appointments, setAppointments] = useState();
  const [userListid, setUserListid] = useState();

  const { keycloak } = useKeycloak();
  useEffect(() => {
    // fetchUrl();
    // fetchcalendarList();
    fetchuserList()
  }, [month])
  const onCurrentDateChange = (date) => {
    setMonth(date)
    // console.log(date)
    // setCurrentDate(date);
};
  // console.log("data to be submitted...", content);
  // console.log(selectUser)
  //VIEW USER
  const fetchuserList = () => {
    let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
    const response = getUserByUserId(filterdata)
    response.then((success) => {
      console.log("success", success)
      //   Object.values(success).map((item) => {
      // if(keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.email === item.email){
      //   setUserListid(item.id)
      success?.id && fetchcalendarList(success.id)
      // }
      //   });
    }, error => {

    })
  }
  // console.log(userListid)
  const fetchcalendarList = (dataid) => {
    console.log('dataid', dataid)
    const selectedMonth = moment(month).format("MM-yyyy");
    const Data = JSON.stringify({
      "userId": dataid,
      "inMonth": selectedMonth
    });
    setLoading(true);
    console.log(Data);
    if(dataid!='undefined'){
    const response = getAllcalendarApis(dataid,Data);
    response.then(
      (success) => {
        console.log("response", success);


        setAppointments([])
       
        Object.values(success).map((item) => {
          let singleShiftidItem = {
            title: item.scheduleTitle,

            startDate: item.startTime,
            endDate: item.endTime,
            location: item.schedulePlace,
            linkCreated: item.linkCreated,
            joinLinkDisable:item.joinLinkDisable,
            link: item.link,
            Organizerid: item.id,
            mode:item.offline,
            status:item.status,
            color: '#cb6bb2',
          }
          setAppointments(prevData => [...prevData, singleShiftidItem]);
        })
        setLoading(false);

      },
      (error) => {
        setErrorObj(error);
        setLoading(false);
        setShowpopup(true);
        setPopup(true);
      }
    );}
  };
  // console.log(appointmentData)
  const currentDate = new Date();
  const views = ['day', 'week', 'workWeek', 'month'];
  // console.log(appointments)
  const Content = (({
    children, appointmentData, ...restProps
  }) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
      <div className="container-fluid ">
      
      {appointmentData.status === "Cancelled" ?
      <small className = "text1">This session has been cancelled</small> :
<div className='row AppointmentTooltip'>
      <div className='col-2 text-center AppointmentTooltipicon'><i className="fas fa-video"></i></div>
      <div className='col-10 px-0 AppointmentTooltiptext'>
      <a href={appointmentData.link} className={appointmentData?.link!=null && !appointmentData?.joinLinkDisable ? "btn m-0 " : "btn m-0 disabled"} target={'_blank'}> {appointmentData.mode === true  ? "Join class" : "Offline"}</a>
        <small> {(appointmentData.link == null && appointmentData.mode === false ) ? "" : "The meeting link is not available"}</small>
      </div>
    </div>}
    </div>

    </AppointmentTooltip.Content>
  ));



  return (
    <>  <div calssName="card">
    <div calssName="card-body">
      <div className="row justify-content-center ">
        {/* <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
          <label htmlFor="idRole" className="labelheading">
            Select Month <span className="errormark"> *</span>
          </label>
          <DatePicker
            className="form-control "
            selected={month}
            onChange={(date) => setMonth(date)}
            dateFormat="MM-yyyy"
            showMonthYearPicker
          />
        </div> */}
      </div>
    </div>
  </div>
   <ThemeProvider theme={theme}>
        <Paper>
          <Scheduler
            data={appointments}
            editing={false}
          >


            <ViewState
              defaultCurrentDate={currentDate}
              defaultCurrentViewName="Month"
              onCurrentDateChange={onCurrentDateChange}

            />

            <WeekView
            // startDayHour={10} endDayHour={19}
            />
            <WeekView
              name="work-week"
              displayName="Work Week"
              excludedDays={[0, 6]}
            // startDayHour={9}
            // endDayHour={19}
            />
            <MonthView />
            <DayView />
            <MonthView />
            <DayView />
            <Toolbar />
            <ViewSwitcher />
            <DateNavigator />
            <TodayButton />
            <Appointments />
            <AppointmentTooltip
              contentComponent={Content}
              showCloseButton
            />
            <CurrentTimeIndicator
              // <CurrentTimeIndicator.IndicatorProps/>	
              shadePreviousCells={true}
              shadePreviousAppointments={true}
              updateInterval={60000} 
              />

          </Scheduler>
        </Paper>
      </ThemeProvider>
    </>

  );
}
export default Calendar;

