import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Loader from "../../DefaultComponent/Loader";
import { useKeycloak } from '@react-keycloak/web';
import LeaderBoard from "./LeaderBoard";
import { Controller, useForm } from "react-hook-form";
import CustomDropDown from "../../DefaultComponent/CustomDropDown";
import Select, { components } from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import { fetchStatistics, fetchTheToprPerformers } from "../../../services/ApiCaller/admin/schoolsmapping";
import { interventionsview } from "../../../services/ApiCaller/admin/intervention";
import nodataFound from '../../../template/img/no_data_found.jpg';





const ViewAllLeaderBoard = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();

    console.log('Find item >>>>>> ', courseId)
    // const [CourseFilterId, setCourseFilterId] = useState("1");
    const { ValueContainer, Placeholder } = components;
    const [courses, setCourses] = useState();
    const [topPerformers, setTopPerformers] = useState([]);
    const [userData, setUserData] = useState(null);
    const [stats, setStats] = useState();
    const [interventions, setInterventions] = useState();


    const {
        formState: { errors },
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
    } = useForm();

    const course = watch("course");


    const [loading, setLoading] = useState(false);
    const [currentUrl, setCurrentUrl] = useState("");
    const { keycloak } = useKeycloak();

    const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
    const [menuCollapse, setMenuCollapse] = useState(
        sidebarCollapsed ? false : true
    );


    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, (child) =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    useEffect(() => {
        fetchTopPerfromers();
        // fetchInterventions()
    }, [course])

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            console.log(value, name, type, "watchhhhh")
        )
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        fetchUrl();
        // fetchStats()
        fetchUserInfo()
    }, []);
    const fetchUrl = () => {
        let urlElements = window.location.href.split("/");
        let urlElelement = urlElements[3];
        setCurrentUrl(`/${urlElelement}`);
    };

    // Fetch User Id

    const fetchInterventions = () => {
        setLoading(true);
        // setAllUnit([]);
        // const response = filterUnit(Data)
        const response = interventionsview();
        response.then(
            (success) => {
                setLoading(false);
                const interventionsData = success.map((intervention) => ({
                    label: intervention.interventionName,
                    value: intervention.id,
                }));
                setValue("intervention", {
                    label: interventionsData[0].label,
                    value: interventionsData[0].value,
                });
                if (interventionsData.length > 0) {
                    fetchTopPerfromers(interventionsData[0]);
                }
                setInterventions(interventionsData);
            },
            (error) => {
                setLoading(false);
                console.log("Error in fetchCourses NewDashboard", error);
                // setErrorObj(error)
                // setShowpopup(true);
                // setPopup(true)
            }
        );
    };

    // Get user ID

    const fetchUserInfo = () => {
        let filterdata =
            keycloak.authenticated &&
            keycloak.tokenParsed &&
            keycloak.tokenParsed.sub;
        const response = getUserByUserId(filterdata);
        response.then(
            (success) => {
                setUserData(success);
                console.log("USER DETAILS", success);
                // fetchCourses(success.id);
                fetchStats(success.id);
                // fetchTimeSpent(success.keycloakUserId)
            },
            (error) => { }
        );
    };

    //Select drop down

    const fetchStats = (userId) => {
        setLoading(true);
        // setAllUnit([]);
        // const response = filterUnit(Data)
        const response = fetchStatistics(userId);
        response.then(
            (success) => {
                setLoading(false);
                setStats(success);
                let findItem = success.courseList.find((item) => item.id === parseInt(courseId))
                console.log('Find Item', success.courseList)
                if (findItem) {

                    setValue('course', { label: findItem.name, value: findItem.id, })


                }
                setCourses(
                    success.courseList.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))
                );

                // const chunk = success.slice(0, success.length > 3 ? 3: success.length);
                // setCourses(chunk);
                // setCourseImg(success.coverImage);
            },
            (error) => {
                setLoading(false);
                console.log("Error in fetchStats NewDashboard", error);
                // setErrorObj(error)
                // setShowpopup(true);
                // setPopup(true)
            }
        );
    };


    // View Leader
    const fetchTopPerfromers = () => {
        // setLoading(true);
        const response = fetchTheToprPerformers(1, course ? course.label : courseId, false);
        response.then(
            (success) => {
                // setLoading(false);CourseFilterId
                setTopPerformers(success);
            },
            (error) => {
                setLoading(false);
                console.log("Error in fetchStats NewDashboard", error);
            }
        );
    };




    return (
        <React.Fragment>
            <Header />
            <div className=" app">
                {/* Sidebar */}
                <Sidebar url={currentUrl} />
                {/* main comp */}
                <main>
                    {!loading ? (
                        <>
                            {/* page title */}
                            {/* <div className='row pageheader justify-content-between'>
                        <div className='col'>
                            <div className='pagetitle'>
                               Calendar
                            </div>
                        </div>
                    </div> */}
                            {/* main page  */}
                            <div className="mainpage">
                                <div class="page_main">
                                    <div class="page_inner">
                                        <div>
                                            <div className="leaderBoard_outer">
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="leader_left_inner">
                                                            <div className="leaderBoard_main">
                                                                <div className="leaderboard_title">
                                                                    <h5>Leader Board</h5>
                                                                </div>
                                                                <div className="leaderboard_sub_heading">
                                                                    <div className="leaderBoard_left">
                                                                        <h5>Top performers</h5>
                                                                    </div>
                                                                    <div className="leaderBoard_right d-flex">
                                                                        <div
                                                                            className="me-4"
                                                                            style={{ width: 250 }}
                                                                        >
                                                                            <label
                                                                                for="idLeaderGroupName"
                                                                                className="form-label leaderBoard_select_label"
                                                                            >
                                                                                {/* <i class="fa-solid fa-sliders me-2"></i> */}
                                                                                Select Course
                                                                            </label>
                                                                            <Controller
                                                                                control={control}
                                                                                render={({
                                                                                    field: { onChange, value, name, ref },
                                                                                }) => {
                                                                                    // const currentSelection = [].find(
                                                                                    //   (c) => c.value === value
                                                                                    // );
                                                                                    const handleInterventions = (
                                                                                        selectedOption
                                                                                    ) => {
                                                                                        onChange(selectedOption);
                                                                                        // setValue("intervention", selectedOption)
                                                                                        let str;
                                                                                        // if (selectedOption != null) {
                                                                                        //     setCourseFilterId(
                                                                                        //         selectedOption.value
                                                                                        //     );
                                                                                        // } else {
                                                                                        //     str = null;
                                                                                        //     setCourseFilterId(str);
                                                                                        // }
                                                                                    };
                                                                                    return (
                                                                                        <Select
                                                                                            components={{
                                                                                                ValueContainer:
                                                                                                    CustomValueContainer,
                                                                                            }}
                                                                                            // isClearable
                                                                                            styles={CustomDropDown}
                                                                                            className="Selectontrol"
                                                                                            menuColor="red"
                                                                                            inputRef={ref}
                                                                                            value={value}
                                                                                            reset
                                                                                            name={name}
                                                                                            options={courses}
                                                                                            placeholder={
                                                                                                <label
                                                                                                    htmlFor="idCourse"
                                                                                                    className="labelheading"
                                                                                                >
                                                                                                    {""}
                                                                                                </label>
                                                                                            }
                                                                                            onChange={handleInterventions}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                                name="course"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="leaderBoard_content_card">
                                                                <div className="row">

                                                                    {topPerformers.length > 0 ?
                                                                        topPerformers.map((item, index) => {
                                                                            return (
                                                                                <LeaderBoard
                                                                                    index={index}
                                                                                    item={item}
                                                                                />
                                                                            );
                                                                        }) :
                                                                        <div className="nodata_found_main">
                                                                            <img src={nodataFound} className="img-fluid noData_image"></img>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </main>
            </div>
            {/* <Header/>
        <div class="page_main">
                <div class="page_inner">
                <Banner image = {bannerImg} styleCss = {'cetf_banner'}/>
                    <div class="certificate_list_outer">
                        <CertificateList cerImage = {demoCert}/>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    );
};

export default ViewAllLeaderBoard;
