import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Loader from "../../DefaultComponent/Loader";
import {
  filterLessonByStatus,
  filterCourseByStatus,
} from "../../../services/ApiCaller/content/lesson";
import CourseItem from "./CourseItem";
import finance2 from "./../../../template/img/plaining.png";
import ModuleItem from "./ModuleItem";
import { useNavigate, useParams } from "react-router-dom";
import comingsoon from "../../../template/img/comingsoon.png";
import Comingsoon from "../../DefaultComponent/appComponent/ComingSoon";

const CourseView = () => {
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed ? false : true
  );
  const [lesson, setLesson] = useState([]);
  const [courseDetails, setcourseDetails] = useState([]);

  const navigate = useNavigate();
  const { courseId } = useParams();

  const manageRedirection = (Coursedata, currentIndex) => {
    const regex = /[^a-zA-Z0-9\s]/g;
    const cName = Coursedata.name.replace(regex, "-");
    // navigate(`${cName}/${courseItemData.id}`, {state: courseItemData});
    navigate(`${cName}/${courseId}/${Coursedata.id}`, {
      state: { modules: lesson, currentCourse: Coursedata },
    });

    // navigate(`${Coursedata.name.replace(" ", "-")}/${courseId}/${Coursedata.id}`,{state: {modules: lesson, currentCourse: Coursedata, moduleIndex: currentIndex}}
    // );
  };
  useEffect(() => {
    fetchUrl();
    fetchlessontName();
    fetchcourseName();
  }, []);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };
  const fetchlessontName = () => {
    setLesson([]);
    //ONLY APPROVED
    setLoading(true)
    const response = filterLessonByStatus(courseId, ["Approved"]);
    response.then(
      (success) => {
        setLoading(false);
        setLesson(success);
        //  setlessonId(success.unit.id);
        // console.log("idddd", success.unit.id)
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };
  const fetchcourseName = () => {
    setcourseDetails([]);
    //ONLY APPROVED
    setLoading(true)
    const response = filterCourseByStatus(courseId, ["Approved"]);
    response.then(
      (success) => {
        setLoading(false);
        setcourseDetails(success);
        //  setlessonId(success.unit.id);
        // console.log("idddd", success.unit.id)
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };

  return (
    <React.Fragment>
      {/* <Header menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}/> */}
      {/* <div className=" app"> */}
      {/* <Sidebar url={currentUrl} menuCollapse={menuCollapse}/> */}
      {/* main comp */}
      {/* <main >
                {!loading ?
                <> */}
      {/* <div className='mainpage' > 
                        <div className="page_main">
                            <div class="page_inner"> */}
      {/* <div className="row justify-content-between">
                                    <div className="col">

                                        <CustomBreadCrumbs/>


                                    </div>
                                </div> */}

      <div className="module_view_main">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
            {/* {lesson && */}
            {/* lesson.map((item, index) => { */}

            {courseDetails && (
              <CourseItem
                // image={finance2}
                // courseName={item.unit.name}
                module="6"
                // duration="4"
                currentStatus="100"
                completionStatus="100"
                coursedata={courseDetails}
                buttonEnable={false}
              />
            )}
            {/* })} */}
            {courseDetails && (
              <div className="module_description">
                <h5 className="description_title">Description</h5>
                <p class="description_design">{courseDetails.description}</p>
              </div>
            )}
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div className="module_item_list">
              {/* <ModuleItem
                module="1"
                moduleName="Lorem ipsum dolor sit amet"
                currentStatus="50"
                manageRedirection={manageRedirection}
              />
              <ModuleItem
                module="2"
                moduleName="Lorem ipsum dolor sit amet"
                currentStatus="40"
                manageRedirection={manageRedirection}
              />
              <ModuleItem
                module="3"
                moduleName="Lorem ipsum dolor sit amet"
                currentStatus="30"
                manageRedirection={manageRedirection}
              />
              <ModuleItem
                module="4"
                moduleName="Lorem ipsum dolor sit amet"
                currentStatus="65"
                manageRedirection={manageRedirection}
              />
              <ModuleItem
                module="5"
                moduleName="Lorem ipsum dolor sit amet"
                currentStatus="0"
                manageRedirection={manageRedirection}
              />
              <ModuleItem
                module="6"
                moduleName="Lorem ipsum dolor sit amet"
                currentStatus="100"
                manageRedirection={manageRedirection}
              />
              <ModuleItem
                module="8"
                moduleName="Lorem ipsum dolor sit amet"
                currentStatus="50"
                manageRedirection={manageRedirection}
              /> */}

              {(!loading && lesson.length === 0) &&
                 <Comingsoon src={comingsoon}/>
                } 
                 {lesson.length > 0 &&
                lesson.map((item, index) => {
                  return (
                    <ModuleItem
                      // module="8"
                      // moduleName={item.name}
                      currentStatus="50"
                      manageRedirection={manageRedirection}
                      coursedata={item}
                      index={index}
                    />
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      {/* </div>
                        </div>
                    </div> */}
      {/* </>
                    :
                    <Loader />
                  }
                </main> */}
      {/* </div> */}
    </React.Fragment>
  );
};

export default CourseView;
