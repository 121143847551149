import React, { useState, useEffect, useRef } from "react";
import { Document, Page } from "react-pdf";
import HTMLFlipBook from "react-pageflip";

const FlipBook = (props) => {
  const bookRef = useRef(null);
  // const [currentPageNumber, setCurrentPageNumber] = useState(0)
  //   useEffect(() => {
  //     renderPdfPages()
  //   }, [])

  const renderPdfPages = () => {
    const pdfPages = [];

    for (let pageNumber = 1; pageNumber <= props.numPages; pageNumber++) {
      pdfPages.push(
        <div key={pageNumber}>
          <Page pageNumber={pageNumber} />
        </div>
      );
    }

    return pdfPages;
  };

  const nextButtonClick = () => {
    // if(bookRef.current){
      console.log(bookRef.current)
      bookRef.current.pageFlip().flipNext();
    // }
  };

  const prevButtonClick = () => {
    // if(bookRef.current){
      bookRef.current.pageFlip().flipPrev();
    // }
  };

  // const onPageFlip = (page) => {
  //   if(page.data === 0){
  //     setCurrentPageNumber(1)
  //   }else{
  //     setCurrentPageNumber(page.data+2)
  //   }
    
  // };

  return (
    //   <div style={{width: 300, height: 500}}>
    //   <PageFlip
    //   // flipOnTouchZone
    //   orientation="horizontal"
    //   responsive
    //   // showHint
    //   width={400}
    //   height={500}
    // >
    //   {renderPdfPages()}
    // </PageFlip>
    //   </div>
    <>
      <HTMLFlipBook
        width={550}
        height={733}
        minHeight={400}
        size="stretch"
        minWidth={315}
        maxWidth={826}
        maxHeight={1533}
        maxShadowOpacity={0.5}
        drawShadow={true}
        autoSize={true}
        showCover={true}
        mobileScrollSupport={true}
        // onFlip={onPageFlip}
        className="demo-book"
        ref={bookRef}
      >
        {renderPdfPages()}
      </HTMLFlipBook>
      <div className="container demo-book-btn">
        <div>
          
          <button type="button" className="pagePrevious" onClick={() => prevButtonClick()}>
          <i class="fa-solid fa-chevron-left"></i>
          </button>
          {/* <span >{` ${currentPageNumber} `}</span> <span style={{fontWeight: 'bold'}}>Of</span><span>{` ${props.numPages} `}</span> */}
          <button type="button" className="pagePrevious" onClick={() => nextButtonClick()}>
          <i class="fa-solid fa-chevron-right"></i>
       
          </button>
        </div>
        {/* <div>
          State: <i>{this.state.state}</i>, orientation:{" "}
          <i>{this.state.orientation}</i>
        </div> */}
      </div>
    </>
  );
};

export default FlipBook;
