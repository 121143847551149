import axios from "axios";
import React, { useEffect, useState } from "react";
import YoutubePlayer from "./lessons/YoutubePlayer";
import VideoPlayer from "./lessons/VideoPlayer";
import ScromViewer from "./lessons/ScromViewer";
import HtmlViewer from "./lessons/HtmlViewer";
import ImageViewerContent from "./lessons/ImageViewerContent";
import LoadContent from "./lessons/LoadContent";
import AudioViewer from "./lessons/AudioViewer";
import YouTube from "react-youtube";
import PdfViewer from "./lessons/PdfViewer";
import DocumentViewer from "./DocumentViewer";
import FlipBookViewer from "./lessons/FlipBookViewer";
import Form from "react-bootstrap/Form";

export default function RenderMedia(props) {
  const [isSelected, setSelection] = useState(false);

  const { lessionItem } = props;
  const [lessonData, setLessonData] = useState(null);

  const opts = {
    // height: '100%',
    width: "100%",
    playerVars: {
      controls: 1, // Show video controls
      disablekb: 1, // Disable keyboard controls
      modestbranding: 1, // Hide YouTube logo
      rel: 0, // Hide related videos
      showinfo: 0, // Hide video title and uploader info
      playsinline: 1,
    },
  };

  useEffect(() => {
    leaderDownloadfile();
  }, []);
  console.log("props", props.lessionItem.fileId);
  function leaderDownloadfile() {
    console.log("props1", props.lessionItem.fileId);
    return new Promise((resolve, reject) => {
      axios
        .get(`files/files/download/base64/${props.lessionItem.fileId}`)
        .then(function (response) {
          console.log("props1", response);
          setLessonData(response.data);
        })
        .catch(function (error) {
          //   reject(error);
        });
    });
  }

  const isFileTypeVideo = () => {
    const fileType = lessionItem.type.toLowerCase();
    return (
      fileType === "avi" ||
      fileType === "mov" ||
      fileType === "mp4" ||
      fileType === "wmv" ||
      fileType === "flv"
    );
  };

  const isFileTypePDF = () => {
    return lessionItem.type.toLowerCase() === "pdf";
  };

  const isFileTypeImage = () => {
    const fileType = lessionItem.type.toLowerCase();
    return fileType === "png" || fileType === "jpg" || fileType === "jpeg";
  };

  const isFileTypePPT = () => {
    const fileType = lessionItem.type.toLowerCase();
    return fileType === "ppt" || fileType === "pptx";
  };

  const isFileTypeAudio = () => {
    return lessionItem.type.toLowerCase() === "mp3";
  };

  const isFileTypeHtml = () => {
    const fileType = lessionItem.type.toLowerCase();
    return fileType === "html" || fileType === "zip";
  };

  const isFileTypeScrom = () => {
    const fileType = lessionItem.type.toLowerCase();
    return fileType === "scorm" || fileType === "zip";
  };

  // const isFileTypeDocx = () => {
  //     return lessionItem.type.toLowerCase() === "docx"
  // }

  const isFileTypeYoutubeVideo = () => {
    const fileType = lessionItem.type.toLowerCase();
    return fileType === "youtube" || fileType === "h5p";
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };

  const isFileTypeDocx = () => {
    return (
      lessionItem.type.toLowerCase() === "docx" ||
      lessionItem.type.toLowerCase() === "doc"
    );
  };

  const isFileTypeXlsx = () => {
    return (
      lessionItem.type.toLowerCase() === "xls" ||
      lessionItem.type.toLowerCase() === "xlsx"
    );
  };

  const handelSwitchChange = () => {
    setSelection(!isSelected);
  };

  return (
    <React.Fragment>
      {isFileTypeVideo() && (
        <VideoPlayer videoId={lessionItem.fileId}></VideoPlayer>
      )}

      {isFileTypeDocx() && (
        <LoadContent fileType={lessionItem.type} filePath={lessonData} />
      )}
      {isFileTypeXlsx() && (
        <div style={{ width: "1500px" }}>
          <LoadContent fileType={lessionItem.type} filePath={lessonData} />
        </div>
      )}

      {isFileTypeImage() && <ImageViewerContent filePath={lessonData} />}

      {isFileTypePDF() && (
        <>
          <div>
            {/* <button>Switch</button> */}
            <Form>
              <div className="switch_main">
                <span className="cstm_label_switch">
                  PDF Swich to Flip Book
                </span>
                <Form.Check
                  checked={isSelected}
                  onChange={handelSwitchChange}
                  type="switch"
                  id="custom-switch"
                />
              </div>
            </Form>
          </div>
          {isSelected ? (
            <FlipBookViewer fileType={lessionItem.type} filePath={lessonData} />
          ) : (
            <LoadContent fileType={lessionItem.type} filePath={lessonData} />
          )}
        </>
      )}

      {isFileTypePPT() && (
        <>
          {/* <iframe
            //   src={`https://docs.google.com/presentation/d/${lessionItem.fileId}/edit?usp=sharing&ouid=110660264790985002571&rtpof=true&sd=true`}
            //   src={`https://docs.google.com/presentation/d/1FvToSQQz8spAMXCZmioRZlPvai0rCVCN/edit?usp=sharing&ouid=110660264790985002571&rtpof=true&sd=true`}
            src={`https://docs.google.com/presentation/d/${lessionItem.fileId}/edit?rm=minimal`}
            width="100%"
            height="600px"
            frameborder="0"
          /> */}


<div className="desktop-view-ppt">
    <iframe
        src={`https://docs.google.com/presentation/d/${lessionItem.fileId}/edit?rm=minimal`}
        width="100%"
        height="600px"
        frameborder="0"
      ></iframe>
   
    </div>
    <div className="mobile-view-ppt">
    <iframe
        title="Google Presentation"
        src={`https://docs.google.com/presentation/d/${lessionItem.fileId}/embed?start=false&loop=false&delayms=3000`}
        width="100%"
        height="600px"
        frameborder="0"
        // allowFullScreen={true}
      ></iframe>
    
    </div>
   
        </>
      )}

      {isFileTypeAudio() && <AudioViewer fileId={"audioFileId"} />}

      {isFileTypeHtml() && <HtmlViewer fileId={lessionItem.htmlFileId} />}

      {isFileTypeScrom() && <ScromViewer fileId={lessionItem.scromFileId} />}

      {/* {isFileTypeDocx() && <LoadContent type={lessionItem.type} filePath={lessionItem.filePath} />} */}
      {/* {isFileTypeDocx() && <DocumentViewer type={lessionItem.type} filePath={lessonData} />} */}

      {isFileTypeYoutubeVideo() && (
        <YouTube videoId={"JgjMiLsx_fg"} opts={opts} onReady={onPlayerReady} />
      )}
    </React.Fragment>
  );
}

// const RenderComp = ({ lessionItem, lessonData }) => {

//     const opts = {
//         height: '390',
//         width: '840',
//         playerVars: {
//             controls: 1,       // Show video controls
//             disablekb: 1,      // Disable keyboard controls
//             modestbranding: 1, // Hide YouTube logo
//             rel: 0,            // Hide related videos
//             showinfo: 0,       // Hide video title and uploader info
//             playsinline: 1,
//         }
//     };
//     const onPlayerReady = (event) => {
//         event.target.playVideo();
//     };

//     if (
//         lessionItem.type === "avi" ||
//         lessionItem.type === "mov" ||
//         lessionItem.type === "mp4" ||
//         lessionItem.type === "wmv" ||
//         lessionItem.type === "flv") {
//         return <VideoPlayer videoId={"773698041"} />
//     }
//     else if (lessionItem.type === "pdf") {
//         return <LoadContent type={"pdf"} filePath={""} />
//     }
//     else if (lessionItem.type === "ppt" || lessionItem.type === "pptx") {
//         return <button
//             type="button"
//             className="btn lmsbtn  btn-sm"
//         //   onClick={() => downloadPPt(lessionItem.pptFileId)}
//         >Download PPT</button>
//     }
//     else if (lessionItem.type === "png" || lessionItem.type === "jpg" || lessionItem.type === "jpeg") {
//         return <ImageViewerContent filePath={lessionItem.filePath} />
//     }
//     else if (lessionItem.type === "scorm" || lessionItem.type === "zip") {
//         return <ScromViewer fileId={lessionItem.scromFileId} />
//     }
//     else if (lessionItem.type === "html" || lessionItem.type === "zip") {
//         return <HtmlViewer fileId={lessionItem.htmlFileId} />
//     }
//     else if (lessionItem.type === "docx") {
//         return <LoadContent type={lessionItem.type} filePath={lessionItem.filePath} />
//     }
//     else if (lessionItem.type === "mp3") {
//         return <AudioViewer fileId={"audioFileId"} />
//     }
//     else if (lessionItem.type.toLowerCase() === "youtube" || lessionItem.type === "h5p") {
//         return <YouTube videoId={"JgjMiLsx_fg"} opts={opts} onReady={onPlayerReady} />
//     }
// }
