import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export default function Question(props) {
  const {
    questionData,
    questionSymbol,
    qIndex,
    attemptedAnswer,
    onAnswerSubmit,
  } = props;

  //================================= Single select ==============================//

  const onAnswerSubmitClick = (choiceText, type) => {
    onAnswerSubmit(qIndex, choiceText, type);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="row justify-content-between">
        <div className="col">
          <div className="Quesbody">
            <h5 className="question_desc">
              {questionSymbol}. {questionData ? questionData.question : ""}
            </h5>
          </div>
        </div>
      </div>

      {questionData.questionType === "Single select MCQ" && (
        <SingleANdMultiSelectQuestion
          index={qIndex}
          type={"radio"}
          options={questionData.options}
          attemptedAnswer={attemptedAnswer.answers}
          onAnswerSubmitClick={onAnswerSubmitClick}
        ></SingleANdMultiSelectQuestion>
      )}

      {questionData.questionType === "Multiple select MCQ" && (
        <SingleANdMultiSelectQuestion
          index={qIndex}
          type={"checkbox"}
          attemptedAnswer={attemptedAnswer}
        ></SingleANdMultiSelectQuestion>
      )}

      {questionData.questionType === "Fill in the blanks" && <FillInThBlank />}

      {questionData.questionType === "True and False" && (
        <RenderTrueOrFalse
          index={qIndex}
          type={questionData.type}
          options={questionData.options}
          attemptedAnswer={attemptedAnswer.answers}
          onAnswerSubmitClick={onAnswerSubmitClick}
        ></RenderTrueOrFalse>
      )}
    </>
  );
}

const RenderTrueOrFalse = (props) => {
  const { qIndex, type, options, attemptedAnswer, onAnswerSubmitClick } = props;
  console.log("RenderTrueOrFalse", attemptedAnswer);
  return (
    <>
      <div key={`inline-${"radio"}`} className="mb-3">
        <Form.Check // prettier-ignore
          type={"radio"}
          id={`${type} ${qIndex}`}
          name={`group${qIndex}`}
          onChange={() => {
            onAnswerSubmitClick("true", type);
          }}
          label={`True`}
          checked={attemptedAnswer.includes("true")}
        />
        <Form.Check // prettier-ignore
          type={"radio"}
          id={`${type} ${qIndex+1}`}
          name={`group${qIndex}`}
          onChange={() => {
            onAnswerSubmitClick("false", type);
          }}
          label={`False`}
          checked={attemptedAnswer.includes("false")}
        />
      </div>
      {/* <div key={`inline-${qIndex+1}`} className="mb-3">
        
        </div> */}
    </>
  );
};

//================================= Multiple options select =====================//
const SingleANdMultiSelectQuestion = (props) => {
  const { qIndex, type, options, attemptedAnswer, onAnswerSubmitClick } = props;

  return (
    <>
      {options.map((choiceText, index) => {
        return (
          <div key={`inline-${index}`} className="mb-3">
            <Form.Check
              onChange={() => {
                onAnswerSubmitClick(choiceText, type);
              }}
              label={choiceText}
              name={`group${qIndex}`}
              id={`${type}${index}`}
              type={type}
              checked={attemptedAnswer.includes(choiceText)}
              value={choiceText}
            />
          </div>
        );
      })}
    </>
  );
};

//================================== Fill in the blank =========================//
const FillInThBlank = () => {
  return (
    <input
      type="text"
      className="w-50 form-control  my-5"
      placeholder="Enter Your Ans"
      value={"fillinBlanks"}
      onChange={(e) => {
        //  handleFillInBlanks(e.target.value)
      }}
    />
  );
};
