import React from 'react';


export default function ImageViewerContent({filePath}) {
  
  return (
    // <h1>img</h1>
    <div className="container-fluid">
    <div className="row">
      <div className="col">
        <div className="card-body frame">
          <img
          className='img-fluid'
            src={filePath}
            alt="loading..."
          />
        </div>
      </div>


    </div>
  </div>
   
  )
}
