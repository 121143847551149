import Vimeo from '@u-wave/react-vimeo';
import React, { useState, useEffect } from 'react';


export default function VideoPlayer({ videoId }) {
  const [data,setDaata]=useState(0)
  console.log("hhhh",videoId)
 
  useState(()=>{
    setDaata(videoId)
    
  })

  useEffect(() => {
    const sideDoc = document.getElementsByClassName("vp-sidedock")[0];
    if (sideDoc) {
      sideDoc.style.display = "none";
    }
  }, []);
 
    return (
        <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card-body">
              
              
                <Vimeo
                showTitle={true}
                video={data}
                className='frame'
                uiTheme="light"
                loop={false}
                sharing={false}
                uiShowStartScreenInfo={false}
                controls={true}
                autoplay
            />
    
            </div>
          </div>


        </div>
      </div>
    )
}
