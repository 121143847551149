import React from 'react';
import './../../App.scss';
import Loader_GIF from '../../../src/util/loader.svg'

export default function loader(props) {
  return (
    <>
      {/* <Dimmer inverted  page>
      <Header style={{ color: '#4d4d4d' }} as='h2' icon inverted>
        <Icon  name='cog' />
        <Header.Content>Keycloak is loading
          <Header.Subheader style={{ color: '#4d4d4d' }}>or running authorization code flow with PKCE</Header.Subheader>
        </Header.Content>
      </Header>
    </Dimmer> */}


      <div className='container-fluid'>
        <div className='row justify-content-center align-content-center h100 m-auto'>
          <div className='col-6 text-center'>    
          {/* <i className="fas fa-cog fa-spin fa-5x redBlue"></i> */}
          {<img src={Loader_GIF}></img>}
          {/* <h5 className='my-5 basefont'>Your Application is loading ..... </h5> */}
          </div>
        </div>
        {/* <div class="loader-container">
        <div class="d-flex-col">
          <span>
            <div class="div"></div>
            <div class="div"></div>
            <div class="div"></div>
            <div class="div"></div>
            <div class="div"></div>
          </span>
        </div>
      </div> */}
      </div>
      
    </>
  )
}
