import { configureStore } from '@reduxjs/toolkit';
import contactReducer from './reducers/contactSlice';
import userLoginInfo from './reducers/AuthService';
import InterventionReducers from './reducers/InterventionReducers';


export default configureStore({
  reducer:{
    contact:contactReducer,
    user:userLoginInfo,
    intervention:InterventionReducers
  }
})