import { createSlice } from '@reduxjs/toolkit';
import { UsersData } from '../../features/DefaultComponent/FakeData';

export const contactSlice = createSlice({
    name:"contact",
    initialState:{
        contacts:UsersData ,
        // get single user Contact details
        contact:{
          name:"",email:"",phone:"",status:""  
        }  
    },
    reducers:{
        deleteUser: (state, action) => {
            state.contacts = state.contacts.filter((user) => user.id !== action.payload);
          },
          getUser :(state,action)=>{
          state.contact = state.contacts.find((item)=>item.id == action.payload)
          },
          updateUser:(state,action)=>{
              state.contacts=state.contacts.map((item)=>
              item.id === action.payload.id ? action.payload : item
              )
          }
      
    }
   

});
export const {deleteUser,getUser,updateUser} = contactSlice.actions

export default contactSlice.reducer;