import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import iconTM from '../../../../template/img/timeout.gif';
import './QuestionAns.scss';

export default function CountdownTimer(props) {
    const [show, setShow] = useState(true);
    const { ass_id } = useParams()
    const cuurrentDate = new Date()
    let datas = moment(cuurrentDate).add(props.time, 'minutes').format('DD MMM YYYY HH:mm:ss')
    const [expiryTime, setExpiryTime] = useState(datas);
    const [countdownTime, setCountdownTime] = useState(
        {
            countdownDays: '',
            countdownHours: '',
            countdownlMinutes: '',
            countdownSeconds: ''
        }
    );
    const countdownTimer = () => {
        const timeInterval = setInterval(() => {
            const countdownDateTime = new Date(expiryTime).getTime();
            const currentTime = new Date().getTime();
            const remainingDayTime = countdownDateTime - currentTime;
            const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
            const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
            const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);
            const runningCountdownTime = {
                countdownDays: totalDays,
                countdownHours: totalHours,
                countdownMinutes: totalMinutes,
                countdownSeconds: totalSeconds
            }
            setCountdownTime(runningCountdownTime);
            if (remainingDayTime < 0) {
                clearInterval(timeInterval);
                setExpiryTime(false);
                props.autoSubmitData()
                const runningCountdownTime = {
                    countdownDays: 0,
                    countdownHours: 0,
                    countdownMinutes: 0,
                    countdownSeconds: 0
                }
                setCountdownTime(runningCountdownTime);
            }
        }, 1000);
    }

    useEffect(() => {
        countdownTimer();
    });
    const handleClose = () => {
        setShow(false)
        props.autoSubmitData()
    }
    return (
        <div>
            {/* {expiryTime !== false ? */}
                <div className='countdownTime'>
                    <div className='col'>
                        <div className='d-flex timer_clock'>
                            <img src={iconTM} className="Totaltimeout ms-2" />
                            <span className="">{countdownTime.countdownHours}</span>
                            <span className="">:</span>
                            <span className="">{countdownTime.countdownMinutes}</span>
                            <span className="">:</span>
                            <span className="">{countdownTime.countdownSeconds}</span>
                        </div>
                    </div>

                </div>
                {/* :
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Exam Over</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='text-center my-5'>
                            <h4>Times Up!</h4>
                            <p>Please Submit ! </p>
                            <p>Otherwise Your Exam will Submitted Automatically</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn lmsbtn"
                            onClick={() => {
                                props.autoSubmitData()
                                setShow(false)
                                // props.closeTrigger(false)
                            }}>
                            Submit
                        </Button>

                    </Modal.Footer>
                </Modal>

            } */}
        </div>
    )
}
