import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { QUIZ_ENABLE_LABEL } from "../../../util/Constants";

const CourseStatus = (props) => {
  const { currentStatus } = props;
  return (
    <React.Fragment>
      <div className="pregress_main">
        {/* <ProgressBar now={currentStatus} /> */}
        <ProgressBar>
          <ProgressBar  now={currentStatus} key={1} />
          {/* <ProgressBar variant="warning" now={20} key={2} /> */}
          {/* <ProgressBar striped variant="danger" now={10} key={3} /> */}
        </ProgressBar>
      </div>
    </React.Fragment>
  );
};

export default CourseStatus;
