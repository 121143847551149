
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web';
import Header from '../../layout/Header';
import Sidebar from '../../layout/Sidebar';
import Loader from '../../DefaultComponent/Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import './reports.scss'
import { useNavigate } from 'react-router-dom';
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import { beneficiaryReport } from '../../../services/ApiCaller/admin/beneficiary/report';
import moment from 'moment';
import { formatDate } from '../../../util/Utilities';


const headerHeight = 55;

export default function Reports() {
    const navigate = useNavigate()
    const { keycloak } = useKeycloak();
    const [loading, setLoading] = useState(false)
    const [currentUrl, setCurrentUrl] = useState("")
    const [showModal,setModalShow] = useState(false) 
    const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
    const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);
    const gridRef = useRef();

    const [data, setData] = useState("");
    const [errorMsg, setErrorMsg] = useState("")
    const [errorObj, setErrorObj] = useState({})
      const [showPopup, setShowpopup] = useState(false)
    const [popup, setPopup] = useState(false)
    const [userId, setUserId] = useState('')


    useEffect(() => {
        fetchUrl();
        fetchuserList();
      }, [])
      //VIEW USER
  const fetchuserList = () => {
    let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
    const response = getUserByUserId(filterdata)
    response.then((success) => {
      reportView(success.id)
    }, error => {
    })
  }

      const reportView = (userId) => {
        const resp = beneficiaryReport(userId)
    resp.then((success) => {
      setLoading(false)
      console.log("report",success)
      setData(success)
      console.log(success,"rowwwww")
    }, error => {
      setLoading(false)
      setErrorObj(error)
      setShowpopup(true);
      setPopup(true)
    })
      }

      const fetchUrl = () => {
        let urlElements = window.location.href.split('/');
        let urlElelement = (urlElements[3])
        setCurrentUrl(`/${urlElelement}`)
      }
 
    const columnDefs = [
        {headerName: "S No.",valueGetter: "node.rowIndex + 1",maxWidth: 100},
        {headerName: "Course Name", field: "courseName", minWidth: 200, cellClass: 'course_name_column'},
        {headerName: "Max Marks", field: "scorePer",  minWidth: 140,
        cellRenderer: (params) => {
          return (params.data.maxMarks ? params.data.maxMarks : "0")
      }
      },
        {headerName: "Marks Scored", field: "passingPer", minWidth: 150,
        cellRenderer: (params) => {
          return (params.data.maxScore ? params.data.maxScore : "0")
      }
      },
        {headerName: "Start Date", field: "startDate", minWidth: 160,
        cellRenderer: (params) => {
          const startDateString = params.data.startDate;

          return moment(startDateString, 'ddd MMM DD HH:mm:ss z YYYY').format('DD-MM-YYYY')
          // return formatDate(params.data.startDate, "DD-MM-YYYY hh:mm")
      }
      },
      {headerName: "End Date", field: "endDate", minWidth: 160,
        cellRenderer: (params) => {
          { console.log(params.data.endDate, "dddddddd") }
          const endDateString = params.data.endDate;
          // "endDate": "2023-11-29 13:54:11.0",
          return endDateString ? moment(endDateString, 'YYYY-MM-DD HH:mm:ss z').format('DD-MM-YYYY') : ""
          // return formatDate(params.data.startDate, "DD-MM-YYYY hh:mm")
      }
      },
        {headerName: "Completion Status", field: "status", minWidth: 150},
        {headerName: "Course Category", field: "courseCatagoreList", minWidth: 170,
        cellRenderer: (params) => {
          const categories = params.data.courseCatagoreList.split(',');
          const categoriesJoin =  categories.join(',')
          return (categoriesJoin)
      }
      },
     {
        headerName: 'Certificate',
        field: 'cerfileId',
        minWidth: 140,
        cellRendererFramework: (params) => (  params.data.cerfileId != null ? 
          <ViewIcon paramsData={params} onCertificateViewClick={onCertificateViewClick} /> : " "
        ),
      },
    ]

    const onCertificateViewClick = (params) => {
        navigate(`/Certificate/CertificateDownload/${params.data.cerfileId}`)
        console.log(params.data.cerfileId,"99999999")

    }

    const [rowData, setRowData] = useState([
        {s_no: 1,course_name: "Toyota", max_marks: "Celica", max_scored: 35000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed",  course_category: "MFD"},
        {s_no: 2,course_name: "Ford", max_marks: "Mondeo", max_scored: 32000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed",  course_category: "MFD"},
        {s_no: 3,course_name: "Porsche", max_marks: "Boxster", max_scored: 72000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed",  course_category: "MFD"},
        {s_no: 4,course_name: "Toyota", max_marks: "Celica", max_scored: 35000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"},
        {s_no: 5,course_name: "Ford", max_marks: "Mondeo", max_scored: 32000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"},
        {s_no: 6,course_name: "Porsche", max_marks: "Boxster", max_scored: 72000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"},
        {s_no: 7,course_name: "Toyota", max_marks: "Celica", max_scored: 35000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"},
        {s_no: 8,course_name: "Ford", max_marks: "Mondeo", max_scored: 32000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"},
        {s_no: 9,course_name: "Porsche", max_marks: "Boxster", max_scored: 72000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"},
        {s_no: 10,course_name: "Porsche", max_marks: "Boxster", max_scored: 72000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"},
        {s_no: 11,course_name: "Porsche", max_marks: "Boxster", max_scored: 72000, start_date: "09-10-2023", end_date: "09-10-2023", completion_status: "Completed", course_category: "MFD"}
    ]);

      const defaultColDef = useMemo(() => {
        return {
          editable: false, 
          wrapText: true, 
          autoHeight: true,
          sortable: true,
          resizable: true,
          filter: true,
          animation: false,
          flex: 1,
          // minWidth: 180,
        };
      }, []);
    return <React.Fragment>
        <Header menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}/>
      <div className=" app">
        {/* Sidebar */}
        <Sidebar url={currentUrl} menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}/>
                {/* main comp */}
                <main >
                {!loading ?
                <>
                    {/* page title */}
                    {/* <div className='row pageheader justify-content-between'>
                        <div className='col'>
                            <div className='pagetitle'>
                               Calendar
                            </div>
                        </div>
                    </div> */}
                    {/* main page  */}
                    <div className='mainpage' > 
                    <div class="page_main">
                        <div class="page_inner reports_main">
                        <p class="h5 mt-3 mb-4 report_table_title">Reports</p>
                        <div style={{height: "655px"}} className="ag-theme-alpine">
                                <AgGridReact
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    headerHeight={headerHeight}
                                    pagination={true}
                                    defaultColDef={defaultColDef}
                                    paginationPageSize={10}
                                    suppressColumnMoveAnimation={false}
                                    >
                                </AgGridReact>
                                {/* <AgGridReact
                        rowData={rowData}
                        paginationPageSize={10}
                        ref={gridRef} // Ref for accessing Grid's API
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        // rowSelection={"multiple"}
                        rowGroupPanelShow={"always"}
                        pivotPanelShow={"always"}
                        enableRangeSelection={true}
                        pagination={true}
                      ></AgGridReact> */}
                            </div>
                        </div>
                    </div>
                    </div>
                    </>
                    :
                    <Loader />
                  }
                </main>
            </div>

        </React.Fragment>
  
}

const ViewIcon = (props) => {
    const {onCertificateViewClick, paramsData} = props
    return <div>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" onClick={() => onCertificateViewClick(paramsData)} style={{cursor: 'pointer'}}>
        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
            fill={"#0A233E"}/></svg>
    </div>
}