import { ReactKeycloakProvider } from '@react-keycloak/web';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import Loader from './features/DefaultComponent/Loader';
import NoMatch from './features/DefaultComponent/NoMatch';
import Loginpage from './features/LoginPage/LoginPage';
// import BatchSchedulingViewApprover from './features/pages/batchscheduling/Approver/BatchSchedulingViewApprover';
import Assignments from './features/pages/Assignments/Assignments';
import Courses from './features/pages/Courses/Courses';
// import Dashboard from './features/pages/Dashboard/Dashboard';

import NewDashboard from './features/pages/newdashboard/NewDashboard';

import Lesson from './features/pages/Materials/Lesson';
import Materials from './features/pages/Materials/Materials';

// import Calendar from './features/pages/schedulerview/calendar';
import Calendarview from './features/pages/Calendar/Calendarview';
import LessonContent from './features/pages/Materials/LessonContent';
import LessonDetails from './features/pages/Materials/LessonDetails';
import SessionList from './features/pages/SessionList/SessionList';
import ViewReportCard from './features/pages/ViewReportCard/ViewReportCard';
import OnAuthenticated from './features/PrivateRoute/OnAuthenticated';
import Public from './features/PrivateRoute/Public';
import keycloak from './services/loginservices';
import './template/all.scss';
import { login, validJwt } from './util';

import AssessmentBeneficiaryView from './features/pages/Assessment/AssessmentBeneficiaryView';
import AssessmentScheduleListApprover from './features/pages/Assessment/AssessmentScheduleListApprover';
import OfflineExam from './features/pages/Assessment/OfflineExam';
import OnlineMcqExam from './features/pages/Assessment/OnlineMcqExam';
import OnlineExam from './features/pages/OnlineExam/OnlineExam';
import ExamTermsConditions from './features/pages/Assessment/Exam/ExamTermsConditions';
import MockView from './features/pages/Assessment/MockView';

//Reports
import StudentAttendenceReport from './features/pages/Reports/StudentAttendenceReport';
import StudentPerformanceReport from './features/pages/Reports/StudentPerformanceReport';
import TeacherAttendenceReport from './features/pages/Reports/TeacherAttendenceReport';
import BatchAttendenceReport from './features/pages/Reports/BatchAttendenceReport';
import InterventionMonthlyReport from './features/pages/Reports/InterventionMonthlyReport';
import BatchSessionsReport from './features/pages/Reports/BatchSessionsReport';


// New component for themes Upgradation
import Certificate from './features/pages/certificate/Certificate';
import CertificateDownload from './features/pages/certificate/CertificateDownload';
import Reports from './features/pages/Reports/Reports';
import Course from './features/pages/course/Course';
import CourseView from './features/pages/course/CourseView';
import CourseRoot from './features/pages/course/CourseRoot';
import CourseDetails from './features/pages/course/CourseDetails';
import ModuleContentView from './features/pages/course/ModuleContentView';
import CorrectAnswer from './features/pages/OnlineExam/CorrectAnswer';
import ExamRoot from './features/pages/OnlineExam/ExamRoot';
import ViewAllLeaderBoard from './features/pages/newdashboard/ViewAllLeaderBoard';








function App() {

  // const dispatch = useDispatch()
  //  const keycloak=useKeycloak()
  const handleOnEvent = (event, error) => {

    switch (event) {
      case 'onAuthSuccess':
        //check token is valid & save token
        if (validJwt(keycloak.token)) {
          login(keycloak.token);
          // props.history.push("/");
        }
        // localStorage.setItem("token",keycloak.token)
        //store authenticated status
        localStorage.setItem("authenticate", keycloak.authenticated)
        // console.log("roles",keycloak.tokenParsed.resource_access.account)

        //  setIsLogged(keycloakauthenticated )
        break;
      case 'onAuthLogout':
        // console.log('authenticated', false);
        if (!keycloak.authenticated) {
          keycloak.logout()
          localStorage.removeItem("authenticate")
          localStorage.removeItem("jwt-secret")
          keycloak.token = null;
        }
        break;
      case 'onTokenExpired':
        keycloak.updateToken(300).then(
          (response) => {
            //I want to update my existing Token
            // console.log("response: ", response)
            // console.log('upauthToken', keycloak.token);
            if (validJwt(keycloak.token)) {
              login(keycloak.token);
              // props.history.push("/");
            }

          })
          .catch(error => {
            // console.log("error: ", error)
          })
        break;
      default:
        // setIsLogged(true )
        break;
    }
  }
  const loadingComponent = (
    <div>
      <Loader />
    </div>
  )

//   document.addEventListener('contextmenu', event => {
//     event.preventDefault();
// });


  return (

    <ReactKeycloakProvider
    initOptions={{ onLoad: 'login-required' }}
      onEvent={(event, error) => handleOnEvent(event, error)}
      LoadingComponent={loadingComponent}
      authClient={keycloak}
    >
      <BrowserRouter  
      >
        <Public>
          <Routes>
            <Route path="/" element={<Loginpage />} ></Route>
            <Route path="*" element={<NoMatch />} ></Route>
          </Routes>
        </Public>

        <OnAuthenticated >
          <Routes>
          {/* <Route path="/sb" element={<SidebarMenu/>}></Route> */}
            <Route path='/Calendarview' element={<Calendarview />} />
            {/* Old Dashboard */}
            {/* <Route path='/' element={<Dashboard />} />
            <Route path='/Dashboard' element={<Dashboard />} /> */}

            {/* New Dashboard */}
            <Route path='/' element={<NewDashboard />} />
            <Route path='/NewDashboard' element={<NewDashboard />} />
            <Route path='/ViewAllLeaderBoard/:courseId' element={<ViewAllLeaderBoard />} />


            <Route path='/Courses' element={<Courses />} />
            <Route path='/Materials' element={<Materials />} />
            {/* <Route path='/Materials' element={<Materials />} /> */}
            <Route path='/Lesson/:id' element={<Lesson />} />
            <Route path='/LessonContent/:lessonId' element={<LessonContent/>} />
            <Route path='/Lessondetails/:details' element={<LessonDetails />} />
            <Route path='/Calendarview' element={<Calendarview />} />
            
            <Route path='/OnlineExam' element={<ExamRoot />} >
              <Route path='' element={<OnlineExam/>}/>
              <Route path='CorrectAnswer/:courseName/:courseId/:moduleName/:lessonId/:sessionId/:userId' element={<CorrectAnswer/>}></Route>
            </Route>

            <Route path='/Assignments' element={<Assignments />} />
            <Route path='/SessionList' element={<SessionList />} />
            <Route path='/ViewReportCard' element={<ViewReportCard />} />
            {/* <Route path='BatchSchedulingViewApprover' element={<BatchSchedulingViewApprover />} /> */}

        


            <Route path='/AssessmentBeneficiaryView' element={<AssessmentBeneficiaryView />} />
            <Route path='/MockView' element={<MockView/>} />
            <Route path='/AssessmentBeneficiaryView' element={<AssessmentBeneficiaryView />} />
            <Route path='/AssessmentScheduleListApprover' element={<AssessmentScheduleListApprover />} />
            <Route path='/termsConditions/:ass_id/:userId' element={<ExamTermsConditions/>} />
            <Route path='/onlineMcqExam/:ass_id/:userId/:totaltime' element={<OnlineMcqExam />} />
            <Route path='/offlineExam/:ass_id' element={<OfflineExam />} />
            
             {/* Reports */}
             <Route path='StudentAttendenceReport' element={<StudentAttendenceReport />} />
            <Route path='StudentPerformanceReport' element={<StudentPerformanceReport />} />
            <Route path='TeacherAttendenceReport' element={<TeacherAttendenceReport />} />
            <Route path='BatchAttendenceReport' element={<BatchAttendenceReport />} />
            <Route path='InterventionMonthlyReport' element={<InterventionMonthlyReport />} />
            <Route path='BatchSessionsReport' element={<BatchSessionsReport />} />
            <Route path='/Reports' element={<Reports/>}/>

            {/* New Route for Theme upgradation */}
            <Route path='/Certificate' element={<Certificate />} />
            <Route path='/Certificate/CertificateDownload/:certificateId' element={<CertificateDownload />} />
            {/* <Route path='/Certificate/CertificateView/:certificateId' element={<CertificateView />} /> */}

            {/* <Route path='/Course' element={<Course />} >
              <Route path='CourseView' element={<CourseView />} />
            </Route> */}
            
            <Route path='/Course' element={<CourseRoot/>}>
              <Route path='' element={<Course />} />
                <Route path=':courseName/:courseId' element={<CourseDetails />} >
                  <Route path='' element={<CourseView/>}></Route>
                  <Route path=':moduleName/:courseId/:lessonId' element={<ModuleContentView/>}></Route>
                </Route>
                <Route path='CorrectAnswer/:courseName/:courseId/:moduleName/:lessonId/:sessionId/:userId' element={<CorrectAnswer/>}></Route>
            </Route>

            
            {/* <Route path='/modules' element={<Modules />} >
              <Route path='' element={<DummyCourses/>}></Route>
              <Route path='dummy_courses/:d_id/lessons' element={<Lessons/>}></Route>
            </Route> */}

            {/* <Route path='/Course' element={<Course />} />
            <Route path='/CourseView' element={<CourseView />} />
            <Route path='/ModuleContentView' element={<ModuleContentView/>} /> */}

          </Routes>
        </OnAuthenticated>
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}


export default App;


