import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './app/store';
import { StateProvider } from './app/state/Store';


ReactDOM.render(
    <React.StrictMode>
      {/* <Provider store={store}>
      <App />
      </Provider> */}
      <StateProvider>
      <App />
      </StateProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();




