import { createSlice } from '@reduxjs/toolkit';


export const AuthService = createSlice({
    name:"user",
    initialState:{
        token:"",
        role:{}
    },
    reducers:{
        addToken :(state,action)=>{
            state.token=action.payload
        },
        retriveToken :(state)=>{
            return state.token
        },
       
         
        
      
    }
   

});
export const {addToken,retriveToken} = AuthService.actions

export default AuthService.reducer;