import React from 'react'
import CustomBreadCrumbs from '../../DefaultComponent/appComponent/CustomBreadCrumbs'
import { Outlet } from 'react-router-dom'

export default function CourseDetails() {
  return (
    <>
        <CustomBreadCrumbs/>
        <Outlet/>
    </>
  )
}
