import React, { useEffect, useReducer, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import bannerImg from "../../../template/img/Quiz_banner.png";
import Banner from "../../DefaultComponent/appComponent/Banner";
import TermsAndConditions from "./TermsAndConditions";
import TermsAndConditionsExam from "./TermsAndConditionsExam";
import OnlineMcqExam from "../Assessment/OnlineMcqExam";
import StartQuiz from "./StartQuiz";
import Score from "./Score";
import { useNavigate, useParams } from "react-router-dom";
import {
  Assessmenttime,
  countAttemptOnContinue,
} from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
import { ASSESSMENT, DATA_NOT_FOUND } from "../../../util/Constants";
import { AssessmentDataByAssessmentId } from "../../../services/ApiCaller/assessment/AssessmentManagement/AssessmentDataManagementController";
import {
  fromQuiz,
  getAssessmentType,
  getTimeInMillies,
} from "../../../util/Utilities";
import moment from "moment";

const initialState = {
  FROM: "QUIZ",
  noDataFound: false,
  quizOrExam: {
    started: false,
    finished: false,
    questions: [],
    startTime: null,
    quizResults: {},
    sessionId: null,
    startDateTime: null
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return { ...state, [action.key]: action.value };
    case "START QUIZ":
      return {
        ...state,
        quizOrExam: {
          ...state.quizOrExam,
          started: true,
          startDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          sessionId: getTimeInMillies(),
        },
      };
    case "FINISH QUIZ":
      return {
        ...state,
        quizOrExam: {
          ...state.quizOrExam,
          finished: true,
          viewScore: true,
          quizResults: { ...action.value },
        },
      };
    case "RESET":
      return { ...initialState };
    default:
      return state;
  }
};

export default function Quiz(props) {
  const { show, onHide, courseid, lessonid, userData, FROM, ExamFlag } = props;
  // const [wasExamStarted, setExamStatus] = useState(false)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quizTotalDuration, setQuizTotalDuration] = useState(0);
  // const [showScore, setShowScore] = useState(false);
  const [quiz, dispatch] = useReducer(reducer, initialState);
  const [questions, setQuestions] = useState([]);

  const { courseName, courseId, lessonId, moduleName } = useParams();

  useEffect(() => {
    dispatch({ type: "CHANGE", key: "FROM", value: FROM });
    fetchAssessmentTime();
  }, []);

  const fetchAssessmentTime = () => {
    const resp = Assessmenttime(courseid, fromQuiz(FROM) ? lessonid : 0);
    resp.then((success) => {
      console.log("ass time", success);
      setQuizTotalDuration(success);
      // dispatch({type: "CHANGE", key: "duration", value: success})
      fetchAssessmentDetails();
    });
  };

  const fetchAssessmentDetails = () => {
    setLoading(true);
    const resp = AssessmentDataByAssessmentId(
      courseid,
      fromQuiz(FROM) ? lessonid : 0,
      getAssessmentType(FROM)
    );
    resp.then(
      (success) => {
        setLoading(false);
        if (success.length > 0) {
          setQuestions(success);
        } else {
          dispatch({ type: "CHANGE", key: "noDataFound", value: true });
        }
      },
      (error) => {
        if (error.status === DATA_NOT_FOUND) {
          dispatch({ type: "CHANGE", key: "noDataFound", value: true });
        }
        console.log("Error in fetchAssessmentDetails Quiz", error.status);
      }
    );
  };

  const countAttempts = () => {
    const attemptedResponse = countAttemptOnContinue(
      userData.id,
      fromQuiz(FROM) ? lessonid : 0,
      courseid,
      getAssessmentType(FROM)
    );
    attemptedResponse.then(
      (responseData) => {
        console.log("responseData responseData responseData", responseData);
        // setTotaltime(success)
      },
      (error) => {}
    );
  };

  const onBackToTermsAndConditions = () => {
    // setExamStatus(false)
    dispatch({ type: "RESET" });
  };

  const onSuccessfulSubmit = () => {
    onHide(!show);
  };

  const dismiss = () => {
    onHide(!show);
  };

  const viewScore = (quizResult) => {
    dispatch({ type: "FINISH QUIZ", key: "quizResults", value: quizResult });
  };

  const onReTakeQuizClick = () => {
    dispatch({ type: "RESET" });
    dispatch({ type: "CHANGE", key: "FROM", value: FROM });
    fetchAssessmentDetails();
  };

  const onCourseContinueClick = () => {
    dismiss();
    navigate("/Course");
  };

  return (
    <Modal
      show={show}
      backdrop={"static"}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter backdrop-false"
      centered
      id="quizModal"
    >
      <Modal.Body>
        <Banner img={bannerImg} styleCss={"exam_banner"} />

        {!quiz.quizOrExam.started &&
          !quiz.noDataFound &&
          (ExamFlag ? (
            <TermsAndConditionsExam
              quizTotalDuration={quizTotalDuration}
              totalQuestion={questions.length}
            />
          ) : (
            <TermsAndConditions
              quizTotalDuration={quizTotalDuration}
              totalQuestion={questions.length}
            />
          ))}

        {quiz.quizOrExam.started && !quiz.quizOrExam.finished && (
          <StartQuiz
            onBackToTermsAndConditions={onBackToTermsAndConditions}
            dismiss={dismiss}
            viewScore={viewScore}
            courseid={courseid}
            lessonid={lessonid}
            questions={questions}
            courseId={courseid}
            lessonId={lessonid}
            userData={userData}
            quizTotalDuration={quizTotalDuration}
            quizState={quiz}
          />
        )}

        {quiz.quizOrExam.started && quiz.quizOrExam.finished && (
          <Score
            onReTakeQuizClick={onReTakeQuizClick}
            onCourseContinueClick={onCourseContinueClick}
            courseid={courseid}
            lessonid={lessonid}
            userData={userData}
            moduleName={moduleName}
            courseName={courseName}
            quizState={quiz}
          />
        )}

        {quiz.noDataFound && (
          <div className="quiz_not_found">
            <p>No data found</p>
          </div>
        )}

        {/* 
            {!showScore && <div>
                {wasExamStarted ? 
                <StartQuiz onBackToTermsAndConditions={onBackToTermsAndConditions} dismiss={dismiss} viewScore = {viewScore} courseid={courseid} lessonid={lessonid}/>
                 : <TermsAndConditions courseid={courseid} lessonid={lessonid}/>}
            </div>}
            {
                showScore && <Score onReTakeQuizClick={onReTakeQuizClick} onCourseContinueClick={onCourseContinueClick} courseid={courseid} lessonid={lessonid}/>
            } */}
      </Modal.Body>
      {!quiz.quizOrExam.started && (
        <Modal.Footer>
          {!quiz.noDataFound && (
            <Button
              className="continue cstm"
              onClick={() => {
                countAttempts();
                dispatch({ type: "START QUIZ" });
              }}
            >
              Continue
            </Button>
          )}
          <Button className="close cstm" onClick={() => dismiss()}>
            Close
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
