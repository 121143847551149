import { createSlice } from '@reduxjs/toolkit';
import { UsersData } from '../../features/DefaultComponent/FakeData';

export const InterventionReducers = createSlice({
    name:"intervention",
    initialState:{
        interventions:[] ,
       
    },
    reducers:{
        addIntervention: (state, action) => {
            state.interventions = action.payload;
          },
      
    }
   

});
export const {addIntervention} = InterventionReducers.actions

export default InterventionReducers.reducer;