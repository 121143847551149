import React, { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import RenderMedia from '../RenderMedia';


export default function ContentViewModal(props) {
const {show,onModalDissmiss, selectedContent} = props;

    useEffect(() => {

    }, [])

    return (
        <React.Fragment>
            <Modal show={show} fullscreen={true} onHide={() => onModalDissmiss()}>
                <Modal.Header closeButton className='content_view_modal_header'>
                    <Modal.Title className='content_modal_title'>
                        <span className="content_visit" style={{width: 6, height: 6, borderRadius: "50%", backgroundColor: selectedContent.visited ? "green" : "red"}}></span> {selectedContent.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='content_body_modal'><RenderMedia lessionItem={selectedContent}></RenderMedia></div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}