import React, { useEffect, useState } from "react";

export default function TermsAndConditions(props) {
  const { quizTotalDuration, totalQuestion, ExamFlag } = props;
  // const [questionsDetails, setQuestionsDetails] = useState([])
  // const [totaltime, setTotaltime] = useState(0);

  useEffect(() => {
    console.log(window.location.search);
    // fetchAssessmentDetails()
    // fetchAssessmentTime();
  }, []);

  // const fetchAssessmentTime = () => {
  //     const resp = Assessmenttime(courseid, lessonid)
  //     resp.then((success) => {
  //       console.log("ass time", success)
  //       setTotaltime(success)
  //       // setTotaltime(success)
  //     })
  //   }
  // const fetchAssessmentDetails = () => {
  //   setLoading(true)
  //   const resp = AssessmentDataByAssessmentId(courseid, lessonid, ASSESSMENT)
  //   resp.then((success) => {
  //     setLoading(false)
  //     setQuestionsDetails(success)
  //   })
  // }
  return (
    // <Modal
    //     show={show}
    //     backdrop={'static'}
    //     size="xl"
    //     aria-labelledby="contained-modal-title-vcenter backdrop-false"
    //     centered
    // >
    //     <Modal.Body>
    //         {wasExamStarted ? <span>HELLO</span> : <div className='container-fluid'>

    //             <Banner img={bannerImg} styleCss={'exam_banner'} />
    <div className="container-fluid">
      {/* <Banner img={bannerImg} styleCss={'exam_banner'} /> */}
      <div className="container-fluid">
        <div className="row pageheader justify-content-between">
          <div className="col-12">
            <div className="instraction_modal_inner">
              <div className="modalpagetitle description_design">
                Instructions for Examination
              </div>
              <ol className="ins_order">
                {/* <li className='description_design'>There will be <strong>{totalQuestion}</strong> questions for digital literacy assessment.</li>
                            <li className='description_design'>For each question only one possible answer will be given.</li>
                            <li className='description_design'>You have to choose the correct one by clicking the mouse.</li>
                            <li className='description_design'>You can attempt exam only 1 time. If your score is below 60% then 1 reattempt allowed after 15 days</li>
                            <li className='description_design'>The time limit will be <strong>{quizTotalDuration}</strong> minutes.</li>
                            <li className='description_design'>User must not stop the session and then return to it. This is especially important in the online environment where the system will "time-out" and not allow the user to re-enter the exam site.</li> */}
                <li className="description_design">
                  There will be <strong>{totalQuestion}</strong> questions for the assessment.
                  {/* There will be <strong>{totalQuestion}</strong> questions for
                  digital literacy assessment. */}
                </li>
                <li className="description_design">
                  For each question only one possible answer will be given.
                </li>
                <li className="description_design">
                  You have to choose the correct one by clicking the mouse.
                </li>
                  <li className="description_design">
                    No negative markings for wrong answers.
                  </li>
                  <li className="description_design">
                    You can attempt the exam only one time. If you fail, you will be given another attempt.
                  </li>
               
                <li className="description_design">
                The time limit will be <strong>{quizTotalDuration}</strong>{" "} minutes.
                  {/* The time limit will be <strong>{quizTotalDuration}</strong>{" "}
                  minutes. */}
                </li>
                <li className="description_design">
                  User must not stop the session and then return to it. This is especially important in the online environment where the system will "time out" and not allow the user to re-enter the exam site.
                </li>
                <li className="description_design">
                  Once you start the exam ensure you complete the submission before moving to another tab/window as the exam will be auto-submitted and you will lose 1 attempt.
                  {/* User must not stop the session and then return to it. This is
                  especially important in the online environment where the
                  system will "time-out" and not allow the user to re-enter the
                  exam site. */}
                </li>{" "}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    //         </div>
    //     }
    //     </Modal.Body>
    //     <Modal.Footer>
    //         <Button variant="primary" onClick={() => setExamStatus(true)}>Continue</Button>
    //     </Modal.Footer>
    // </Modal>
  );
}
