
import axios from '../../axiosconfig';
// usercreate
export function userdelete(data) {
   return  new Promise((resolve,reject)=>{ 
    axios.post('user/api/v1/lms-user/delete',data,{
        headers: {
            //'Access-Control-Allow-Origin':'*',
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
        // console.log('Error on Authentication');
      });
    });
}
// user delete by id
export function userdeletebyid(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`user/api/v1/lms-user/deleteById/${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

//  user lms create
 export function userlmscreate(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('user/api/v1/lms-user/create',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

// user by id
export function userbyid(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`user/api/v1/lms-user/user${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

// user test
export function usertest() {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`user/api/v1/lms-user/test`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// user list test
export function userlisttest() {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`user/api/v1/lms-user/test`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

// user list 
export function userLists(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`user/api/v1/lms-user/getUserByUserId/${data}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
//get User By User Id
export function getUserByUserId(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`user/api/v1/lms-user/getUserByUserId/${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}


//User enable
export function Userenable(userId,flag) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`user/api/v1/lms-user/enable/${userId}/${flag}/`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
  }

