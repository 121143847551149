import React from "react";
import "./../newdashboard/newDashboard.scss";
import CourseStatus from "./../newdashboard/CourseStatus";
import defaultImage from "../../../template/img/exam_sample.png";
import { useNavigate } from "react-router-dom";

const CourseItem = (props) => {
  const navigate = useNavigate();
  console.log("item details", props.coursedata.name);
  const {
    module,
    currentStatus,
    completionStatus,
    description,
    manageRedirection,
    moduleRedirectionButton = true,
    coursedata,
    buttonEnable,
  } = props;

  const getButton = (status) => {
    // if(status === '0'){
    //     return <button className='card_continue_btn' onClick={()=>{manageRedirection()}}>Start</button>
    // }else if(status === '100'){
    //     return <button className='card_complete_btn' onClick={()=>{manageRedirection()}}>Completed</button>
    // }else{
    //     return <button className='card_continue_btn' onClick={()=>{manageRedirection()}}>Continue</button>
    // }
    // return status === '0' ? 'card_continue_btn' : status === '100' ? 'card_complete_btn' : 'card_continue_btn';

    return (
      <button
        className={(coursedata.daysRemains > 0 && coursedata.id) ? "card_continue_btn" : "card_continue_btn disable"}
        onClick={() => {
          manageRedirection(coursedata);
        }}
      >
        Start
      </button>
    );
  };

  return (
    <React.Fragment>
      {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-sm-12"> */}
      <div className="card cstm_course_card">
        <img
          src={coursedata.coverImage ? coursedata.coverImage : defaultImage}
          className="card-img-top course_img"
          alt="Course Image"
        />
        <div className="card-body course_card_body">
          <h5 className="card-title">{coursedata.name}</h5>
          {/* <p className="module_desc">{coursedata.description}</p> */}

          <p className="module_details">
            {coursedata.lessonCount} Modules{" "}
            {coursedata.daysRemains > 0 &&
              <span className="clock_icon">
                <i class="fa-regular fa-clock me-1"></i>{" "}
                {coursedata.daysRemains} Days
              </span>
            }
            {
              coursedata.daysRemains <= 0 && <span className="expired-txt">Expired</span>
            }
          </p>
          <div className="course_status">
            <CourseStatus currentStatus={coursedata.completedPercentage} />{" "}
          </div>
          <span className="complete_module_text">
            {coursedata.completedPercentage}% Completed
          </span>
          {buttonEnable && (
            <>
              {moduleRedirectionButton && (
                <div className="course_card_btn">
                  {/* <div className="navigate_to">
                <span
                  className="navigate_icon_ac"
                  onClick={() => {
                    manageRedirection(coursedata);
                  }}
                >
                  Start
                </span>

                
              </div> */}

                  {getButton(completionStatus)}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

export default CourseItem;
