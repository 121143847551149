import React from 'react';
import './lessons.css';

const ManageQuiz = (props) => {
    const {quizTitle, onQuizStartClick} = props;
    return(
        <React.Fragment>
        <div className='quiz_box_main' onClick={()=>{onQuizStartClick()}}>
            <div className='quiz_left'>
                <p className='quiz_box_title'>{quizTitle}</p>
            </div>
            <div className='quiz_right'>
                {/* <div className='duration_sec'>
                    <span>{quizDuration}</span>
                </div> */}
                <div className='quiz_arrow_icon'>
                    <span><i class="fa-solid fa-angle-right"></i></span>
                </div>
            </div>
        </div>
            
        </React.Fragment>
    )
}

export default ManageQuiz;