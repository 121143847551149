import React from 'react';
import { ENV } from '../../../util/env';

export default function AudioViewer({fileId}) {
    // let fileId='a1658333630712';
    return (

        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="card-body AUDIO_URL frame">
                            <iframe
                                src={ENV.AUDIO_URL+fileId+'.html'}
                                frameborder="0"
                                allow="autoplay"
                            >
                            </iframe>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
