export const userUIRole = {
    // intervention
    "create_intervention": "create_intervention",
    "view_intervention": "ui_lms_view_intervention",
    "mapping_intervention": "ui_lms_mapping_intervention",
    "school_mapping": "ui_lms_school_mapping",
    "view_school_mapping": "ui_lms_view_school_mapping",


    //user admin
    "view_Activate_User_Roles": "ui_lms_Activate_User_Roles",
    "create_Admin_User": "ui_lms_create_Admin_User",
    "create_Trainer_User": "ui_lms_create_Trainer_User",
    "create_Beneficiary_User": "ui_lms_create_Beneficiary_User",
    "View_User_list": "ui_lms_View_User_list",
 //Beneficiary 
 "Student_Bulk_Upload": "ui_lms_Student_Bulk_Upload",
 "Leader_Bulk_Upload": "ui_lms_Leader_Bulk_Upload",
 "View_history_Bulk": "ui_lms_history_Bulk",
 //batch creation maker
 "batch_creation_maker":"ui_lms_history_Bulk",
 "batch_creation_maker_view":"ui_lms_history_Bulk",
 "batch_creation_Approvar_view":"ui_lms_history_Bulk",
 //batch scheduling
 "batch_schedule_create":"ui_lms_history_Bulk",
 "batch_schedule_view_maker":"ui_lms_history_Bulk",

 



    // "activate_user_role":"ui_lms_activate_user_role",
    // "assistant_manager":"ui_lms_assistant_manager",
    // "content_manager": "ui_lms_content_manager",
    // "data_manager": "ui_lms_data_manager",
    // "Kef_teacher": "ui_lms_kef_teacher",
    // "operations_manager": "ui_lms_operations_manager",
    // "parents": "ui_lms_parents",
    // "process_and_technology_manager": "ui_lms_process_and_technology_manager",
    // "program_head": "ui_lms_program_head",
    // "school_coordinator": "ui_lms_school_coordinator",
    // "school_headmaster": "ui_lms_school_headmaster",
    // "school_principal": "ui_lms_school_principal",
    // "school_teacher": "ui_lms_school_teacher",
    // "school_trustee": "ui_lms_school_trustee",
    // "student": "ui_lms_student",
    // "Volunteer": "ui_lms_volunteer",
    // "admin_user": "ui_lms_admin_user",
    // "beneficiary_user": "ui_lms_beneficiary_user",
    // "add_user": "ui_lms_add_user",
    // "update_user": "ui_lms_update_user",
    // "user_type_create":"ui_lms_user_type_create",
    // "view_user": "ui_lms_view_user",
    // "test_creation":"ui_lms_test_creation",
    // "view_test_creation":"ui_lms_view_test_creation",
    // "student_bulk_upload": "ui_lms_student_bulk_upload",
    // "leader_bulk_upload": "ui_lms_leader_bulk_upload",
    // "create_batch": "ui_lms_create_batch",
    // "view_batch": "ui_lms_view_batch",
    // "classify_students": "ui_lms_classify_students",
    // "view_classify_students": "ui_lms_view_classify_students"
 }