
import icons from "../template/img/icons8-ok.gif"
export const ErrorMessages = {
   "api_Responses":
   {
      "error_code_400": "Something is wrong [Bad Request]", "icon": "" ,
      "error_code_409":  "Something is wrong [Internal Server Error]", "icon": "" ,
      "error_code_500": "Technical Difficulties", "icon": "" ,
      "error_code_other": "Something is wrong ", "icon": "" 
   },
   "modal_Errors":
   {
      "add": [{ "msg": "Sorry, this record is not added", "icon": "" }],
      "edit": [{ "msg": "The details was not updated successfully", "icon": "" }],
      "delete": [{ "msg": "Congratulations!! Your record is not removed", "icon": "" }],
      "submit": [{ "msg": "Sorry, there was an error while submitting your data", "icon": "" }],
      "upload": [{ "msg": "Sorry, we could not upload the file", "icon": "" }],
      "download": [{ "msg": "Download failed", "icon": "" }]
   }
}



export const successMessages = {
   "Form_Submit":
   {
      "Success": [{ "msg": "Details has been Submitted Successfully", "icon": { icons } }],
      "update": [{ "msg": "Details has been Updated Successfully", "icon": { icons } }],
      "upload_Success": [{ "msg": "Uploaded Successfully", "icon": { icons } }],
      "download_Success":[{ "msg": "Download Successfull", "icon": { icons } }]
   },
   "modal":
   {
      "intervention": [{ "msg": "Intervention has been Added Successfully", "icon": { icons } }],
      "operations":[{ "msg": "Operation Successful", "icon": { icons } }],
      "activate":[{ "msg": "Successfully Activated", "icon": { icons } }],
      "deactivate":[{ "msg": "Successfully deactivated", "icon": { icons } }],
      "schoolCreation": [{ "msg": "School has been Created Successfully", "icon": { icons } }],
      "testCreation": [{ "msg": "Test has been Created Successfully", "icon": { icons } }],
      "testUpdation": [{ "msg": "Test has been Updated Successfully", "icon": { icons } }],
      "testApproval": [{ "msg": "Test has been Approved Successfully", "icon": { icons } }],
      "scheduleApproval": [{ "msg": "Schedule has been Approved Successfully", "icon": { icons } }],
      "batchApproval" : [{ "msg": "Batch has been Approved Successfully", "icon": { icons } }],
      "approval": [{ "msg": "Request has sent for  Approval", "icon": { icons } }],
      "batchCreation": [{ "msg": "Batch has been Created Successfully", "icon": { icons } }],
      "batchUpdation": [{ "msg": "Batch has been Updated Successfully", "icon": { icons } }],
      "batchScheduling": [{ "msg": "Batch has been Scheduled Successfully", "icon": { icons } }],
      "add": [{ "msg": "Record successfully added", "icon": { icons } }],
      "edit": [{ "msg": "Details successfully updated", "icon": { icons } }],
      "delete": [{ "msg": "Record removed successfully", "icon": { icons } }],
      "submit": [{ "msg": "Record submitted successfully", "icon": { icons }}],
      "batchApproval": [{ "msg": "Sent for approval", "icon": { icons }}],
      "ApprovedOrReject" : [{ "msg": "Operation successfully completed", "icon": { icons } }],
      "assessment_add": [{ "msg": "Assessment has been created successfully", "icon": { icons }}],
      "assessment_edit": [{ "msg": "AAssessment has been updated successfully", "icon": { icons }}],
      "assessment_schedule": [{ "msg": "Assessment has been scheduled successfully", "icon": { icons }}],
      "assessment_schedule_edit": [{ "msg": "Assessment schedule has been updated successfully", "icon": { icons }}],
      "delete_assessment": [{ "msg": "Assessment removed successfully", "icon": { icons }}],
      "delete_schedule": [{ "msg": "Assessment schedule removed successfully", "icon": { icons }}],
      "submitmeg":  [{ "msg": "Congratulations You have Submitted Your Exam Successfully !", "icon": { icons }}],
     }
}


export const ValidationMessages = {
   "Field_Required": "This Field can not be left blank!",
   "userName": "Should be in a correct format!",
   "userEmail": "Should be in a correct format!",
   "mobileNo": "Should be in a correct format!",
   "id": "Should be in a correct format!",
   "interventionName": "Should be in a correct format!",
   "testName": "Should be in a correct format!",
   "batchName": "Should be in a correct format!",
   "schoolName": "Should be in a correct format!",
   "topicName": "Should be in a correct format!",
   "assignmentName": "Should be in a correct format!",
   "dueDate": "Should be in a correct format!",
   "academicYear": "Should be in a correct format!",
   "feedback": "Should be in a correct format!"
}
export const Modal_QueryMsg = {
   "add": "Do You Really Want to Add ?",
   "edit": "Do You Really Want to Edit ?",
   "delete": "Do You Really Want to Delete ?",
   "active": "Want to Activate ?",
   "deactive": "Want to de-activate ?",
   "submit": "Do you Really Want to Submit ?",
   "upload": "Are You Sure You Want to Upload ?",
   "download": "Are You Sure You Want to Download ?",
   "approval":  "Send For Approvel ? ",
   
}


