
import React, { useState } from 'react';
import { Col, Row, Toast as ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorMessages } from '../../util/Messaging';
import "./errorpage.css";
//  import ToastContainer from 'react-bootstrap/ToastContainer';
export default function ErrorMsg(props) {
    const [show, setShow] = useState(true)
    const [position] = useState('bottom-end');
    console.log("props.trigger",props.trigger)
  
   const showMsg = () =>{
       if(typeof(props.errorStatus)==="object")
       {
           if(props.errorStatus.status===400 || props.errorStatus.status===404 || props.errorStatus.status===415)
           {
               return(
                <p>{props.errorStatus.data.message ? props.errorStatus.data.message : ErrorMessages.api_Responses.error_code_400}</p> 
               )
           }
           else if(props.errorStatus.status===500 || props.errorStatus.status===503)
           {
            return(
                <p>{ErrorMessages.api_Responses.error_code_500}</p> 
               )
           }
           else{
               return(
                <p>{ErrorMessages.api_Responses.error_code_other}</p>  
               )
           }
       }
       else 
       {
        return(
            <p>{ErrorMessages.api_Responses.error_code_other}</p> 
           )  
       }
   }
    return (props.trigger) ?
        (
            <ToastContainer position="bottom-start" className="position-fixed tostyfy_main">
                <Toast
                    onClick={() => props.setTrigger(false)}
                    variant="danger"
                    position='bottom-end'
                    //dont use these onclose properties
                    // onClose={() => setShow(false)} 
                    // show={show} 
                    >
                    <Row className="justify-content-center align-items-center">
                        <Col xs={10} >
                            <Toast.Body variant="danger">
                           {showMsg()}
                            </Toast.Body>
                        </Col>
                        <Col xs={2}>
                            <Toast.Header className="justify-content-end border-0 toastheader">
                            </Toast.Header>
                        </Col>
                    </Row>
                </Toast>
            </ToastContainer>) : ''


}
