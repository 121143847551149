const CustomDropDown = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "",
      backgroundColor: state.isSelected ? "#003366" : "",
      padding: 15,
      // marginRight: 15,
      "&:hover": {
        backgroundColor: "#003975d9",
      },
    }),
    control: (base, state) => ({
      ...base,
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1px solid black",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "1px solid black",
        borderRadius: "0",
      },
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#eeeeee",
        borderRadius: 5,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#000000",
    }),
  
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: "#f2cbcb",
        borderRadius: 2,
        color: '#c02424',
      },
    }),
    
      valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible",
        marginBottom: "-15px",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginLeft: "-5px",
        marginTop: "0px",
        // textTransform: "uppercase !important",
      }),
      menu: (provided, state) => {
        return {
          ...provided,
          zIndex: 9999,
          // textTransform: "uppercase !important",
        };
      },
      placeholder: (provided, state) => ({
        ...provided,
        position: "relative",
        MaxWeight: "auto !important",
        fontFamily: "$lms_Regular  !important",
        fontStyle: "normal  !important",
        fontWeight: "300  !important",
        lineHeight: "18px  !important",
        // fontSize: "18px !important",
        color: "#000",
        marginBottom: "10px  !important",
        top: state.hasValue || state.selectProps.inputValue ? -17 : -10,
        transition: "top 0.1s",
        fontSize:  state.hasValue || state.selectProps.inputValue ? "15px !important" : "18px !important",
        opacity: state.hasValue || state.selectProps.inputValue ? 0.6 : 1,
        paddingBottom:
          state.hasValue || state.selectProps.inputValue ? "2px" : "5px",
        marginLeft: "-5px",
      }),
    };
    
    export default CustomDropDown;