import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Loader from '../../DefaultComponent/Loader'
import Header from '../../layout/Header'
import Sidebar from '../../layout/Sidebar'

export default function CourseRoot() {
    const [loading, setLoading] = useState(false)
    const [currentUrl, setCurrentUrl] = useState("")
    const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
    const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);

    useEffect(() => {
        fetchUrl();
    }, [])
    const fetchUrl = () => {
        let urlElements = window.location.href.split('/');
        let urlElelement = (urlElements[3])
        setCurrentUrl(`/${urlElelement}`)
    }

    return (
        <React.Fragment>
            <Header />
            <div className=" app">
                {/* Sidebar */}
                <Sidebar url={currentUrl}/>
                {/* main comp */}
                <main >
                    {!loading ?
                        <>
                            <div className='mainpage' >
                                <div class="page_main">
                                    <div class="page_inner">
                                        <Outlet></Outlet>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <Loader />
                    }
                </main>
            </div>
            </React.Fragment>
            )
}
