import React,{useState,useEffect} from 'react';
import demoImage from './../../../template/img/BG_certificate.png';
import './certificate.scss';
import Header from '../../layout/Header';
import Sidebar from '../../layout/Sidebar';
import Loader from '../../DefaultComponent/Loader';
import { useNavigate,  useParams, useLocation } from 'react-router-dom';
import { leaderDownloadfile, pptDownLoad } from '../../../services/ApiCaller/file/fileManagement';
import LoadContent from '../course/lessons/LoadContent';
import axios from 'axios';
import moment from 'moment';
import { fetchCertificateListView } from '../../../services/ApiCaller/content/unit';




const CertificateDownload = () => {
    const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
    const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);
    const [loading, setLoading] = useState(false)
    const [currentUrl, setCurrentUrl] = useState("")
    // const {state} = useLocation();
    const [active, setActive] = useState("");
    const[filePath,setFilePath]=useState('')
    const {certificateId} = useParams()

    const [file, setFile] = useState("")
    const [fileType,setFileType]=useState('')
    const [certificateDetails,setCertificateDetails]=useState(null)
  
    // const {certificatedata} = state
    // console.log(state, "datadownload")
    useEffect(() => {
      fetchUrl();
      fetchFileDetails();
      fetchFileDetailsView();
    }, [])
    const fetchUrl = () => {
      let urlElements = window.location.href.split('/');
      let urlElelement = (urlElements[3])
      setCurrentUrl(`/${urlElelement}`)
    }


    const fetchFileDetails = () => {
        // setActive(file.name)
        console.log("file",file.source)
        // if( file.source==="FileMgmtService" && file.type!="pptx" && file.type!="ppt" && file.type!="scorm" && file.type!="html" && file.type!="zip" && file.source!="Vimeo" && file.source!="MistServer"){
        //   setFileType(file.type)
          const response = leaderDownloadfile(certificateId)
          response.then(
            (success) => {
              console.log("success", success)
              setFilePath(success)
            },
            (error) => {
              setLoading(false)
            //   setErrorObj(error);
            //   setShowpopup(true);
            //   setPopup(true);
            }
          );
        // }
        // if(file.source==="Vimeo"){
        //   setFileType(file.type)
        //   console.log("file is", file.fileId)
        //   setVideoId(file.fileId)
        // }
        // if(file.source==="MistServer"){
        //   setFileType(file.type)
        //   setAudioFileId(file.fileId)
        // }
        // if(file.type==="scorm" || file.type==="zip"){
        //   setFileType(file.type)
        //   setScromFileId(file.fileId)
          
        // }
        // if(file.type==="html" || file.type==="zip"){
        //   setFileType(file.type)
        //   setHtmlFileId(file.fileId)
          
        // }
        // if( file.type==="ppt" || file.type==="pptx"){
        //   setFileType(file.type)
        //   setPptFileId(file.fileId)
        // }
      }
      const fetchFileDetailsView = () => {
        // setActive(file.name)
        console.log("file",file.source)
        // if( file.source==="FileMgmtService" && file.type!="pptx" && file.type!="ppt" && file.type!="scorm" && file.type!="html" && file.type!="zip" && file.source!="Vimeo" && file.source!="MistServer"){
        //   setFileType(file.type)

          const response = fetchCertificateListView(certificateId)
          response.then(
            (success) => {
              console.log("successviewwwwwww", success)
              setCertificateDetails(success)
            },
            (error) => {
              setLoading(false)
            //   setErrorObj(error);
            //   setShowpopup(true);
            //   setPopup(true);
            }
          );
       }

      const contentDownload = () => {
          const response = leaderDownloadfile(certificateId)
          .then(function (response) {
            let base64Res = removeBase64Prefix(response.data)
            downloadPdfFromBase64(base64Res, 'Certificate.pdf');
          })
           .catch(function (error) {
              console.log("Issue in downloading certificate", )
           });
      }


      function removeBase64Prefix(base64String) {
        const prefix = 'data:application/pdf;base64,';
        if (base64String.startsWith(prefix)) {
          return base64String.slice(prefix.length);
        } else {
          return base64String;
        }
      }

      function downloadPdfFromBase64(base64String, fileName) {
        const binaryData = atob(base64String);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName || 'DownloadedFile.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      
    
      
      
      
 
    
    return(
        <React.Fragment>
         <Header menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}/>
            <div className=' app' >
                {/* Sidebar */}
                <Sidebar url={currentUrl} menuCollapse={menuCollapse}/>
                {/* main comp */}
                <main >
                {!loading ?
                <>
                    {/* page title */}
                    {/* <div classNameName='row pageheader justify-content-between'>
                        <div classNameName='col'>
                            <div classNameName='pagetitle'>
                               Calendar
                            </div>
                        </div>
                    </div> */}
                    {/* main page  */}
                    <div className='mainpage' > 
                        <div className='page_main'>
                        <div className="page_main_inner">
                        <div className="page_inner cer_down">
                            <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                            <div className="cstm_certificate_outer">

                                {/* <img src={demoImage} className='img-fluid dynamic_certificate_img'></img> */}
                                {/* <p>{filePath}</p> */}
                                <LoadContent fileType={"pdf"} filePath={filePath.data} />


                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                            <div className="certificate_right_details cer_downlo">
                                <p className="cer_right_cnt cer_course_name">Course Name : {certificateDetails?.courseNam}</p>
                                <p className="cer_right_cnt certificate_complte_date">Completion Date : {certificateDetails?.completiondate ? moment(certificateDetails.completiondate).format("DD-MM-YYYY")  : "NA"}</p>
                                <p className="cer_right_cnt certificate_score">Total Score: {certificateDetails?.score} %</p>
                                <div className="cer_download_btn_area">
                                    <button className="cer_download_btn" onClick={() => contentDownload()}><i className="fa-solid fa-download me-2"></i>Download My
                                        Certificate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                        </div>
                    </div>
                        </div>
                    </div>
                    </>
                    :
                    <Loader />
                  }
                </main>
            </div>
        {/* <Header/>
        <div className="page_main">
                <div className="page_inner">
                <Banner image = {bannerImg} styleCss = {'cetf_banner'}/>
                    <div className="certificate_list_outer">
                        <CertificateList cerImage = {demoCert}/>
                    </div>
                </div>
            </div> */}

        </React.Fragment>
    )
}

export default CertificateDownload;