import { useKeycloak } from "@react-keycloak/web";
import { useContext, useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useNavigate } from "react-router-dom";
import assessmenticon from "../../template/img/assessment approver.svg";
import book from "../../template/img/study_material.svg";

import Batch_Schedule_Approver from "../../template/img/Group-1.svg";
import Dashboard_icon from "../../template/img/Vector(1).svg";
import Calendar_icon from "../../template/img/Vector2.svg";
import "../layout/layout.scss";
import { GetSvgIcon } from "../../util/SvgIcons";
import { MEDIUM_SCREEN_PIXEL_SIZE } from "../../util/Constants";
import * as Actions from '../../app/state/Actions';

const Sidebar = (props) => {
  // const [Calendar, setCalendar] = useState(false)
  // const [sessionList, setSessionList] = useState(false)
  // const [assignments, setAssignments] = useState(false)
  // const [courses, setCourses] = useState(false)
  // const [dashboard, setDashboard] = useState(false)
  // const [onlineexam, setOnlineexam] = useState(false)
  // const [Materials, setMaterials] = useState(false)
  // const [viewReportCard, setViewReportCard] = useState(false)
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const [userroles, setUserroles] = useState(["roles"]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate()
  const {state, dispatch} = Actions.getStateDispatch(useContext)
  const {sidebarCollapse} = state.appReducer

  console.log("State", state)

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    console.log("windowSize.innerWidth", windowSize.innerWidth)
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // useEffect(() => {
  //   if (props.url === "/Dashboard") {
  //     setDashboard(true)
  //   }
  //   if (props.url === "/Courses") {
  //     setCourses(true)
  //   }
  //   if (props.url === "/Materials") {
  //     setMaterials(true)
  //   }
  //   if (props.url === "/Calendar") {
  //     setCalendar(true)
  //   }
  //   if (props.url === "/OnlineExam") {
  //     setOnlineexam(true)
  //   }
  //   if (props.url === "/Assignments") {
  // setAssignments(true)
  //   }
  //   if (props.url === "/SessionList") {
  // setSessionList(true)
  //   }
  //   if (props.url === "/ViewReportCard") {
  //     setViewReportCard(true)
  //   }
  // }, [props.url])
  const { keycloak } = useKeycloak();

  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");

  // const [menuCollapse, setMenuCollapse] = useState(
  //   sidebarCollapsed ? false : true
  // );
  // const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");

  // const [menuCollapse, setMenuCollapse] = useState(
  //   sidebarCollapsed == null ? false : true
  // );

  
  // const [state, setState] = useState({
  //       open: window.matchMedia("(min-width: 479px)").matches || false,
  //     });
    
  //     const ontoggleNav = () => {
  //       setState((prevState) => ({
  //         open: !prevState.open,
  //       }));
  //       // alert(ocalStorage.getItem("sidebar-collapsed"))
  //       if (menuCollapse === true) {
  //         setMenuCollapse(false);
  //         localStorage.removeItem("sidebar-collapsed");
  //       } else {
  //         localStorage.setItem("sidebar-collapsed", true);
  //         setMenuCollapse(true);
  //       }
  //     };
  //     const { open } = state;
  //     const mobile = window.matchMedia("(max-width: 479px)").matches;
    
  //     if (mobile && open) {
  //       document.body.style.overflow = "hidden";
  //     } else {
  //       document.body.style.overflow = "unset";
  //     }

  //create a custom function that will change menucollapse state from false to true and true to false
  // const menuIconClick = () => {
  //   //condition checking to change state from true to false and vice versa
  //   // menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  //   if (menuCollapse) {
  //     setMenuCollapse(false);
  //     localStorage.setItem("sidebar-collapsed", true);
  //     return;
  //   }
  //   setMenuCollapse(true);
  //   localStorage.removeItem("sidebar-collapsed");
  // };

  const collapseSidebar = () => {
    if(windowSize.innerWidth <= MEDIUM_SCREEN_PIXEL_SIZE){ 
      console.log("IF")
      dispatch(Actions.stateChange("appReducer", "sidebarCollapse", false))
    }
  }

  return (
    <div className={!sidebarCollapse ? "sidenavClose" : "sidenavOpen"}>
    <ProSidebar collapsed={!sidebarCollapse}>
      <SidebarContent>
        {/* <div className="closemenu" onClick={menuIconClick}>
          {menuCollapse ? (
            <>
            <button className="btn sidebar-collapse-btn">
            <i class="fa-solid fa-angle-right icon_size"></i>
            </button>
            </>
          ) : (
            <button className="btn sidebar-collapse-btn">
              <i className="fa-solid fa-angle-left icon_size" />
            </button>
          )}
        </div> */}
        {/* <Menu>
          <MenuItem
            className={props.url === "/Dashboard" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"dashboard"} color={props.url === "/Dashboard" ? "white" : "#0d2f55"}></GetSvgIcon>}
          >
            <Link to="/Dashboard">Dashboard </Link>
          </MenuItem>
        </Menu> */}

      {/* New Dashboard */}
        <Menu onClick={ collapseSidebar }>
          <MenuItem
            className={props.url === "/NewDashboard" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"dashboard"} color={props.url === "/NewDashboard" ? "white" : "#0d2f55"}></GetSvgIcon>}
            >
            <Link to={true ? "/NewDashboard" : ""}>Dashboard </Link>
          </MenuItem>
        </Menu>

        <Menu onClick={ collapseSidebar }>
          <MenuItem className={props.url === "/Course" ? "active_submenu" : "inactive_submenu"} 
          icon={<GetSvgIcon iconName={"courses"} color={props.url === "/Course" ? "white" : "#0d2f55"}></GetSvgIcon>}
          >
            <Link to="/Course"  >Courses </Link></MenuItem>
        </Menu>

        {/* <Menu >
          <MenuItem className={props.url === "/OnlineExam" ? "active_submenu" : "inactive_submenu"} icon={<GetSvgIcon iconName={"exam"} color={props.url === "/OnlineExam" ? "white" : "#0d2f55"}></GetSvgIcon>}>
            <Link to="/OnlineExam"  >
              Exam</Link></MenuItem>
        </Menu> */}
        <Menu onClick={ collapseSidebar }>
          <MenuItem
            className={props.url === "/OnlineExam" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"exam"} color={props.url === "/OnlineExam" ? "white" : "#0d2f55"}></GetSvgIcon>}
            >
            <Link to="/OnlineExam">Exam </Link>
          </MenuItem>
        </Menu>

        <Menu onClick={ collapseSidebar }>
          <MenuItem
            className={props.url === "/Reports" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"reports"} color={props.url === "/Reports" ? "white" : "#0d2f55"}></GetSvgIcon>}
            >
            <Link to="/Reports">Reports </Link>
          </MenuItem>
        </Menu>
        

        {/* <Menu>
          <MenuItem
            className={props.url === "/Calendarview" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"calendar"} color={props.url === "/Calendarview" ? "white" : "#0d2f55"}></GetSvgIcon>}
          >
            <Link to="/Calendarview" >Calendar </Link>
          </MenuItem>
        </Menu> */}

       

        {/* <Menu >
          <MenuItem className={props.url === "/OnlineExam" ? "active_submenu" : ""} icon={<img src={Online_Exam_icon} />
          }><Link to="/OnlineExam"  >
              Online Exam</Link></MenuItem>
        </Menu>
        <Menu >
          <MenuItem className={props.url === "/Assignments" ? "active_submenu" : ""} icon={<img src={Assignments_icon} />
          }><Link to="/Assignments"  >

              Assignments</Link></MenuItem>
        </Menu> */}

        {/* <Menu>
          <MenuItem
            className={props.url === "/SessionList" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"settings"} color={props.url === "/SessionList" ? "white" : "#0d2f55"}></GetSvgIcon>}
          >
            <Link to="/SessionList" >Session List</Link>
          </MenuItem>
        </Menu> */}

        {/* <Menu>
          <MenuItem
            className={props.url === "/Materials" ? "active_submenu" : "inactive_submenu"}
            icon={<img src={book} />}
          >
            <Link to="/Materials" >Study Materials </Link>
          </MenuItem>
        </Menu> */}

        {/* <Menu >
          <MenuItem className={props.url === "/ViewReportCard" ? "active_submenu" : ""} icon={<img src={View_Report_Card_icon} />
          }><Link to="/ViewReportCard"  >
              View Report Card </Link></MenuItem>
        </Menu> */}

        {/* <Menu >
          <SubMenu
            className={test_maker ? "active_menu" : "white"}
            icon={<img src={Batch_Schedule_Approver} />
            }
            title='Batch Scheduling'
          >
            <MenuItem><Link to="/BatchSchedulingViewApprover" >Schedule Status View</Link></MenuItem>
          </SubMenu>
        </Menu> */}
        {/* Session */}


        

        {/* Assessment*/}

        {/* {
          AuthorizedFunction([
            userUIRole.uint_maker_creation || userUIRole.uint_view_maker 
            ||
            userUIRole.Lesson_view_maker || userUIRole.Lesson_maker_creation
            ||
            userUIRole.Content_view_maker || userUIRole.Content_maker_creation
          ]) && */}




        {/* <Menu>
          <SubMenu
            className={
              props.url === "/ViewGradeSubmitList" ? "active_submenu" : "inactive_submenu"
            }
            icon={<img src={assessmenticon}  />}
            title="Assessment"
          >
            {
              // AuthorizedFunction([userUIRole.uint_view_maker ]) &&
              <MenuItem
                className={
                  props.url === "/AssessmentBeneficiaryView"? "active_submenu": "white"}>
                <Link to="/AssessmentBeneficiaryView" > Assessment Beneficiary View</Link>
              </MenuItem>
            }
            
            {
               <MenuItem
               className={props.url === "/MockView" ? "active_submenu": "white"}>
               <Link to="/MockView" > Mock Beneficiary View</Link>
               
             </MenuItem>
            }
           
          </SubMenu>
        </Menu> */}

        {
          <Menu onClick={ collapseSidebar }>
          <MenuItem
            className={props.url === "/Certificate" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"certificate"} color={props.url === "/Certificate" ? "white" : "#0d2f55"}></GetSvgIcon>}
            >
            <Link to="/Certificate" >Certificate</Link>
          </MenuItem>
        </Menu>
        }




        {/* } */}
        {/* Report */}
{/* 
        {AuthorizedFunction([
          userUIRole.Assessment_Approvar_view ||
            userUIRole.AssessmentSchedule_Approvar_view,
        ]) && (
          <Menu>
            <SubMenu
              className={report ? "active_menu" : "white"}
              icon={<img src={assessment} className="w-50" git />}
              title="Reports"
            >
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_view_maker,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/StudentAttendenceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/StudentAttendenceReport">
                    {" "}
                    Student Attendence Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/StudentPerformanceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/StudentPerformanceReport">
                    {" "}
                    Student Performance Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/TeacherAttendenceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/TeacherAttendenceReport">
                    {" "}
                    Teacher Attendence Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/BatchAttendenceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/BatchAttendenceReport">
                    {" "}
                    Batch Attendence Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/InterventionMonthlyReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/InterventionMonthlyReport">
                    {" "}
                    Intervention Monthly Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/BatchSessionsReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/BatchSessionsReport"> Batch Sessions Report</Link>
                </MenuItem>
              )}
            </SubMenu>
          </Menu>
        )} */}

      </SidebarContent>
    </ProSidebar>
    </div>
  );
};

export default Sidebar;