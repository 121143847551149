import React from "react";


export default function Comingsoon(props){
    const {src} = props
    return (
        <div>
            <div className="card cstm_course_card">
            <img
                src={src}
                className="card-img-top content_img"
                alt="course_image"
            />
            </div>
        </div>
    )
}