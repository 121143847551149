

// import { useKeycloak } from '@react-keycloak/web';
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import icon from "../../../template/img/footer-logo.svg";
// import Loader from "../../DefaultComponent/Loader";
// import Header from "../../layout/Header";
// import "../../layout/layout.scss";
// import Sidebar from "../../layout/Sidebar";
// import "./assessment.scss";

// import moment from 'moment';
// import { scheduleAssessmentByUserId } from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
// import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";

// export default function OfflineExam() {
//   //view modal
//   const navigate = useNavigate();
//   const { keycloak } = useKeycloak();
//   const [sessionData, setSessionData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [currentUrl, setCurrentUrl] = useState("");

//   const [examMode, setExamMode] = useState('')
//   const [onlineExamPatterns, setOnlineExamPatterns] = useState('')
//   const [assessmentfileType, setAssessmentfileType] = useState([])





//   useEffect(() => {
//     fetchUrl();
//     fetchuserList()
//   }, []);
//   //VIEW USER
//   const fetchuserList = () => {
//     let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
//     const response = getUserByUserId(filterdata)
//     response.then((success) => {
//       fetchListOfAssessments(success.id)
//     }, error => {
//     })
//   }
//   const fetchListOfAssessments = (userId) => {
//     setLoading(true)
//     const resp = scheduleAssessmentByUserId(userId)
//     resp.then((success) => {
//       setLoading(false)
//       setSessionData(success)
//       Object.values(success).map((item) => {
//         setAssessmentfileType((prevData) => [...prevData, item.assessment.fileType]);
//       });
//     },error=>{
//       //add error msg
//       setLoading(false)
//     })
//   }

//   const fetchUrl = () => {
//     let urlElements = window.location.href.split("/");
//     let urlElelement = urlElements[4];
//     setCurrentUrl(`/${urlElelement}`);
//   };
//   //Select exam type online or offline
//   const selectExamType = (ass_id,fileType) => {
//     //Here we have to fetch exam type from api
//     // setExamMode('Online')
//     // setOnlineExamPatterns('MCQ')
//     // console.log("assessment",ass_id)
//     // console.log(sessionData.assessment.mode)
//     // setMfileType
//     // navigate(`/onlineMcqExam/${ass_id}`)

//     if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
//       navigate(`/onlineMcqExam/${ass_id}`)
//     } 
//      else if (fileType === "application/pdf") {
//       navigate(`/offlineExam/${ass_id}`)
//     }


//   }
//   // const renderComp = () => {

//   // }


//   return (
//     <>
//       {!loading ? (
//         <>
//           <Header />
//           <div className=" app">
//             {/* Sidebar */}
//             <Sidebar url={currentUrl} />
//             {/* main comp */}
//             <main>
//               {/* page title */}
//               <div className="row pageheader justify-content-between">
//                 <div className="col">
//                   <div className="pagetitle">Assessment Beneficiary View</div>
//                 </div>
//               </div>
//               {/* main page  */}


//               <div className="container-fluid" >
//                 {!loading ? <><div className='row'>
//                  <>dddd</>

//                 </div></>
//                   :
//                   <Loader />
//                 }


//               </div>

//             </main>
//           </div>
//         </>
//       ) : (
//         <Loader />
//       )}
//     </>
//   );
// }






import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { assessmentdownloadTemplate, assessmentuploadTemplate } from "../../../services/ApiCaller/assessment/AssessmentSchedule/downloadTemplate";
import { getUserByUserId } from '../../../services/ApiCaller/user/usercontroller';
import { successMessages } from "../../../util/Messaging";
import ErrorMsg from '../../DefaultComponent/ErrorMsg';
import Loader from '../../DefaultComponent/Loader';
import SuccessMsg from '../../DefaultComponent/SuccessMsg';
import Header from "../../layout/Header";
import '../../layout/layout.scss';
import Sidebar from '../../layout/Sidebar';
// import './beneficiarycreation.scss';
import Select from "react-select";
import { getAllMasterByCategoryCode } from '../../../services/ApiCaller/master/masterapi';
import "./assessment.scss";
export default function OfflineExam() {
  const { ass_id } = useParams()
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [currentUrl, setCurrentUrl] = useState("")

  //SHOW sUCCESS MSGS
  const [successMsg, setSuccessMsg] = useState("")
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [triggerSuccess, setTriggerSuccess] = useState(false)

  //SHOW ERROR MSGS
  const [errorMsg, setErrorMsg] = useState("")
  const [showPopup, setShowpopup] = useState(false)
  const [popup, setPopup] = useState(false)
  const [errorObj, setErrorObj] = useState({})

  const [assessmentfileType, setAssessmentfileType] = useState([])
  const [sessionData, setSessionData] = useState([]);
  const [assessmentid, setAssessmentid] = useState("");
  const [assessmentidfields, setAssessmentidfields] = useState("");

  const [fileTypes, setFileTypes] = useState([])
  const [fileType, setFileType] = useState('')
  const [getuserid, setGetuserid] = useState('')
  useEffect(() => {
    fetchUrl();
    fetchuserList()
    fetchFileTypes()
    setAssessmentidfields(ass_id.split('-KEF')[1])
    setAssessmentid(ass_id.split('-KEF')[0])
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "",
      backgroundColor: state.isSelected ? "#003366" : "",
      padding: 15,
      '&:hover': {
        backgroundColor: "#003975d9"
      }
    }),
    control: (base, state) => ({
      ...base,
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1px solid black",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "1px solid black",
        borderRadius: "0",
      },
    }),
  };
  const fetchFileTypes = () => {
    setFileTypes([]);
    const fileId = 110;
    const response = getAllMasterByCategoryCode(fileId);
    response.then((success) => {
      const filterOutData = success.subCategories.filter(x => x.subCategoryValue !== "scorm")
      Object.values(filterOutData).map((item) => {
        let singleShiftidItem = {
          label: item.subCategoryValue,
          value: item.subCategoryValue,
        };
        setFileTypes((prevData) => [...prevData, singleShiftidItem]);
      });
    });
  };
  //VIEW USER
  const fetchuserList = () => {
    let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
    const response = getUserByUserId(filterdata)
    response.then((success) => {
      setGetuserid(success.id)
      // fetchListOfAssessments(success.id)
    }, error => {
    })
  }

  const fetchUrl = () => {
    let urlElements = window.location.href.split('/');
    let urlElelement = (urlElements[4])
    setCurrentUrl(`/${urlElelement}`)
  }



  const DownLoadDoc = () => {
    setLoading(true)
    const response = assessmentdownloadTemplate(assessmentidfields);
    response.then((success) => {
      setLoading(false)
      setSuccessMsg(successMessages.Form_Submit.download_Success)
      setShowSuccessMsg(true)
      setTriggerSuccess(true)
    }, error => {
      setErrorObj(error)
      setLoading(false)
      setShowpopup(true);
      setPopup(true)
    }
    )
  }
  const handleUpload = (e) => {
    setFileName(e.target.files[0].name)
    setFile(e.target.files[0])
    // const file = (e.target.files[0].size / 1024 / 1024).toFixed(2);
    // const filetype = e.target.files[0].type;
    // if (file < 1) {
    //   if (filetype != "") {
    //     setFileName(e.target.files[0].name)
    //     setFile(e.target.files[0])
    //   }
    //   else {
    //     setFileName()
    //     setFile()
    //     alert("Please upload ");
    //   }
    // }
    // else {
    //   setFileName()
    //   setFile()

    //   // alert("dd",file.size)
    // }
  }
  // const handleUpload = (event) => {  
  //   setShowFileName(event.target.files[0].name)
  //   setFileName(event.target.files[0])
  //  }
  const onSubmit = () => {
    let ext = ''
    var a = file.type;
    var extension = a.split("/")[1];
    let lowercase_extension = extension.toLowerCase();
    console.log("real", lowercase_extension)
    if (lowercase_extension === "vnd.openxmlformats-officedocument.wordprocessingml.document") {
      ext = "docx";
    }
    else if (lowercase_extension === "mpeg") {
      ext = "mp3";
    }
    else if (lowercase_extension === "mov" || lowercase_extension === "quicktime") {
      ext = "mov";
    }
    else if (lowercase_extension === "wmv" || lowercase_extension === "x-ms-wmv") {
      ext = "wmv";
    }
    else {
      ext = lowercase_extension;
    }
    if (ext !== fileType) {
      alert("Document Extension is not matched with document Type!")
      setLoading(false)
    }
    else {
      setLoading(true)
      const data = new FormData()
      data.append('file', file)
      data.append('assessmentId', assessmentid)
      data.append('userId', getuserid)
      data.append('fileType', fileType)
      console.log(data)
      const result = assessmentuploadTemplate(data);
      result.then((success) => {
        setLoading(false)
        setSuccessMsg(successMessages.modal.submitmeg)
        setShowSuccessMsg(true)
        setTriggerSuccess(true)
        setTimeout(() => {
          if(window?.location?.search =="?mock=true"){
            navigate('/MockView')
          }
          else{
            navigate(`/AssessmentBeneficiaryView`)
          }
        }, 3000);
        //

      }, error => {
        setErrorObj(error)
        setLoading(false)
        setShowpopup(true);
        setPopup(true)
      })
    }

  }


  return (

    <>
      {
        !loading ?
          <>
            <Header />

            <div className=' app' >
              {/* Sidebar */}
              < Sidebar url={currentUrl} />
              {/* main comp */}
              <main >
                {/* page title */}
                <div className='row pageheader justify-content-between'>
                  <div className='col'>
                    <div className='pagetitle'>

                      Assignment Upload
                    </div>
                  </div>
                  {/* <div className='col d-flex justify-content-end'> <button
                    type="button"
                    className="btn lmsbtn btn-sm"
                    onClick={() => setInterventionsShow(true)}
                  >
                    Add Intervention
                  </button>
                  </div> */}
                </div>

                {/* main page  */}
                <div className='mainpage' >
                <div>
                          <div className="form-group formgroup col-md-6 col-xxl-6 col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12">
                            <label htmlFor="idAcademicYear" className="labelheading">
                              File Type
                              <span className="errormark"> *</span>
                            </label>
                            <Controller
                              control={control}
                              style={{ borderBottom: errors.school ? '1px solid red' : '' }}
                              render={({ field: { onChange, value, name, ref } }) => {
                                const currentSelection = fileTypes.find(
                                  (c) => c.value === value
                                );
                                const handleSession = (selectedOption) => {
                                  onChange(selectedOption?.value);
                                  let str;
                                  if (selectedOption != null) {
                                    const lowercase_filetype = selectedOption.value.toLowerCase();
                                    setFileType(lowercase_filetype)
                                  }
                                  else {
                                    str = null;
                                    setFileType(str)

                                  }
                                };
                                return (
                                  <Select
                                    styles={customStyles}
                                    className="Selectontrol"
                                    menuColor="red"
                                    inputRef={ref}
                                    value={currentSelection}
                                    name={name}
                                    onChange={handleSession}
                                    options={fileTypes}
                                  />
                                );
                              }}
                              name="File"
                              rules={{
                                required: 'Select File Type'
                              }}
                            />
                            {errors?.File?.type &&
                              <small className="text-danger">{errors.File.message}
                              </small>}

                          </div>
                        </div>
                  <div className="form-body">

                    <div className="row ">
                      <div className="form-group formgroup col-md-6 col-xxl-6 col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12">
                        <label htmlFor="idfile" className="labelheading">
                          Download Question Paper
                        </label>
                       
                          <div className="dragarea">
                            <span className='header'><p className='header'><i className="fa fa-cloud-download fa-4x mb-4" aria-hidden="true"></i></p></span>
                            <span className="header">
                              Download Question  Paper
                            </span>
                          </div>
                          <div className='text-center'>

                            <button type="submit" className="btn lmsbtn ml-3" onClick={() => DownLoadDoc()}> Download  Question Paper
                            </button>
                          </div>
                       
                      </div>

                      <div className="form-group formgroup col-md-6 col-xxl-6 col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12">
                      
                        <form>
                          <label className="labelheading ">
                            Upload Answer <span className="errormark"> *</span>
                          </label>



                          <div className="">
                            <div className="dragarea">

                              <div className='filetext'><input
                                type="file"
                                className="form-control Filestyle mb-0"
                                id="idfile"
                                onChange={(e) => handleUpload(e)} /></div>
                              {/* <p className='header mt-6'><i className="fa fa-cloud-upload fa-4x" aria-hidden="true"></i></p>
                              <p className="header">Drag & Drop</p>
                              <p className="subheader "> Your files here Or Browse to Upload</p>
                              <p className='subheader case'>{fileName}</p> */}
                              <p className="header mt-5">
                              <i
                                className="fa fa-cloud-upload fa-4x"
                                aria-hidden="true"
                              ></i>
                            </p>
                            <p className="header">Drag & Drop</p>
                            <p className="subheader">
                              {" "}
                              Your files here Or Browse to Upload
                            </p>
                            <p className="subheader case">{fileName}</p>

                            </div>
                            <div className='text-center'>
                              <button type="button"
                                className="btn lmsbtn ml-3"
                                onClick={handleSubmit(onSubmit)}
                              >
                                Upload Answer
                              </button></div>
                            {
                              showSuccessMsg ?
                                <SuccessMsg
                                  msg={successMsg}
                                  setTrigger={setShowSuccessMsg}
                                  trigger={triggerSuccess}
                                />
                                : ''
                            }
                            {showPopup ?
                              <ErrorMsg
                                errorStatus={errorObj}
                                setTrigger={setShowpopup}
                                trigger={popup}
                              /> : ''}


                          </div>
                        </form>
                      </div>

                      {/* <form onSubmit={fileSubmitHandler}>     
     <input type="file"  multiple onChange={uploadFileHandler}/>     
     <button type='submit'>Upload</button>
     {!fileSize && <p style={{color:'red'}}>File size exceeded!!</p>}
     {fileUploadProgress && <p style={{color:'red'}}>Uploading File(s)</p>}
    {fileUploadResponse!=null && <p style={{color:'green'}}>{fileUploadResponse}</p>}
  </form>   */}

                    </div>



                    <span id="idSuccess"></span>
                  </div>
                </div>
              </main>
            </div>
          </> :
          <Loader />
      }

    </>
  )
}