import React, { useState } from 'react'
import { useEffect } from 'react'
import Questions from './Questions';

export default function QuizQuestion({questions,setQuestions,totaltime,selectedIndex,asseId,userId,mock}) {
    const [options,setOptions]=useState();
    const [currentQues,setCurrentQues]=useState(0)
    useEffect(()=>{
    },[questions,currentQues,selectedIndex])
   
  
  
  return (
    <div>
    
        {
         Object.values(questions).length > 0 ? 
        <Questions
        questions={questions}
        currentQues={currentQues}
        setCurrentQues={setCurrentQues}
        totaltime={totaltime}
        selectedIndex={selectedIndex}
        asseId={asseId}
        userId={userId}
       mock={mock}
        // handleSubmitData={handleSubmit}
        />
        :''} 
        </div>
  )
}
