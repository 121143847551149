import React from "react";
import './../../../App.scss';


const Banner = (props) =>{
    const {img,styleCss} = props;
    return(
        <React.Fragment>
            <div classNameName="row">
                <div className="col-12">
                    <div className={styleCss}>
                        <img src={img} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Banner;