import * as utils from '../../util/Utilities'
import state from "./initialState.json"
import { store } from "./Store";

export const UTILS = utils
export const initialState = utils.cloneData(state)
export const resetState = utils.cloneData(state)

export const LOADER_ACTIVE = "LOADER_ACTIVE"
export const STATE_CHANGE = "STATE_CHANGE"


export const getStateDispatch = (context) => {
  const globalState = context(store);
  return {dispatch: globalState.dispatch, state: globalState.state};
};

// export const getState = (context) => {
//   const globalState = context(store);
//   return globalState.state;
// };

export const stateChange = (reducerKey, key, value) => {
  return {
    type: STATE_CHANGE,
    reducerKey,
    key,
    value,
  };
};
 
