import React, { useEffect } from 'react'
import { ENV } from '../../../util/env'

export default function HtmlViewer({ fileId }) {
  console.log("as props....", fileId)
  useEffect(() => {

  }, [])
  return (

    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card-body">
              <iframe
                width="100%"
                height="900px"
                // src= ENV={}.HTML_URL"http://13.233.166.196/+{fileId}"
                src={ENV.HTML_URL + fileId}
                // src={ENV.HTML_URL}
                // src={fileId}
                frameborder="0"
                allow="autoplay"
              >
              </iframe>
            </div>
          </div>


        </div>
      </div>
    </>

  )
}
