import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import React from 'react'
import { center } from 'react-dom-factories';
import '../../pages/OnlineExam/exam.scss'

export default function StatusModal(props) {
    const {message="Success"} = props
    return (
        <Modal
            {...props}
            size="md"
            backdrop={'static'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-90w"
        >
            <Modal.Body>
                <div className='container-fluid my-3'>
                    <div className='container-fluid'>
                        <div className='row my-3'>
                            <div className='d-flex flex-row bg-red justify-content-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                            </div>
                        </div>
                    </div>
                    <p style={{ textAlign: 'center' }}>{message}</p>
                </div>
            </Modal.Body>
        </Modal>
    );
}