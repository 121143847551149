import { useKeycloak } from "@react-keycloak/web";

const OnAuthenticated = ({ children }) => {
 const { keycloak } = useKeycloak();

 const isLoggedIn = keycloak.authenticated;

 return isLoggedIn ? children : null;
};
//  isLogin()  ?(
//                 validRole(hasRole)?
//                 <Component {...props} />: <Redirect to="/forbidden" />
//             )
// return isLoggedIn ? (
//     isAdmin(keycloak)? children: ''
//     )
//  : 
//  ''
// }

export default OnAuthenticated