import React from "react";
import './newDashboard.scss';


const Statistic = (props) => {
    const {stat_no, stat_title, icon, color} = props;
    return(
        <React.Fragment>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-sm-12">
                <div className="statistic_box">
                    <div className="stat_left">
                        <h6 className="stat_no">{stat_no}</h6>
                        <p className="stat_title">{stat_title}</p>
                    </div>
                    <div className="stat_right">
                        <span className="dash_icon_wrapper" style = {{backgroundColor: color}}>{icon}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Statistic;