import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import React from 'react'
import { center } from 'react-dom-factories';
import './exam.scss'

export default function AutoSubmitModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            backdrop={'static'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-90w"
            id="idSubmitModal"
        >
            <Modal.Body>
                <div className='container-fluid my-3'>
                    <p style={{ textAlign: 'center' }}></p>
                    <div className='text-center my-3'>
                            <h4>Times Up!</h4>
                            <p>Please Submit ! </p>
                        </div>
                    <div className='container-fluid'>
                        <div className='row my-3'>
                            <div className='d-flex flex-row bg-red justify-content-center gap-2'>
                                <button type='button' className='submit-exam-btn yes' style={{width: 70}} onClick={() => {props.submitTheAssessement()}}>Submit</button>
                                {/* <button type='button' className='submit-exam-btn no' style={{width: 70}} onClick={() => {props.onHide()}}>No</button> */}
                            </div>
                        </div>


                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}