import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { AssessmentDataByAssessmentId } from '../../../services/ApiCaller/assessment/AssessmentManagement/AssessmentDataManagementController';
import { Assessmenttime } from '../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController';
import Header from '../../layout/Header';
import Loader from './../../DefaultComponent/Loader';
import Sidebar from './../../layout/Sidebar';
import CountdownTimer from './Exam/CountdownTimer';
import IndexList from './Exam/IndexList';
import QuizQuestion from './Exam/QuizQuestion';

export default function OnlineMcqExam(props) {
  const {ass_id, totaltime, userId} = props
  // const { ass_id ,totaltime, userId} = useParams()
  const [questions,setQuestions]=useState([])
  const [loading, setLoading] = useState(false);
 const [selectedIndex,setSelectedIndex]=useState(0)


  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    // fetchUrl();
    // fetchAssessmentDetails()
    // fetchAssessmentTime();
  }, []);

  // const fetchAssessmentDetails = () =>{
  //   setLoading(true)
  //   const resp = AssessmentDataByAssessmentId(ass_id)
  //   resp.then((success)=>{
  //   setLoading(false)
  //   const data = Object.values(success).sort((a, b) => a.srNo - b.srNo)
  //   console.log("///////////////////////////////////////",data)
  //    setQuestions(data)
  //   })
  // }
  // const fetchAssessmentTime = () =>{
  //   const resp = Assessmenttime(ass_id)
  //           resp.then((success)=>{
  //              console.log("ass time",success)
  //              setTotaltime(success)
  //     // setTotaltime(success)
  //           })
  // }
//    useEffect(()=>{
   
//             const resp = Assessmenttime(ass_id)
//             resp.then((success)=>{
//                console.log("ass time",success)
//                setTotaltime(success)
//       // setTotaltime(success)
//             })
//       // const data = `${Math.floor(totaltime / 60)}:${totaltime % 60}`;
//       // setTotaltime(data)
//            let myInterval = setInterval(() => {
//             if (totaltime === 0) {
//                 clearInterval(myInterval)
//             } else {
//               setTotaltime(totaltime - 1);
//             }
        
//     }, 1000)
//     return ()=> {
//         clearInterval(myInterval);
//       };
            
//  })

 
  return (
   
    <>
    {!loading ? (
      <>
        {/* <Header /> */}
       


        {/* <div className=" app">
          <main> */}
            {/* page title */}
            {/* <div className="row pageheader justify-content-between">
              <div className="col">
                <div className="pagetitle">Start Assessment</div>
              </div>
            </div> */}
         
            
            {/* <CountdownTimer time={totaltime && totaltime}  /> */}

              <div className="row">
                <div className='col-12'>
                  {/* Hover Demo*/}
                  <div className="column">
                    {
                      questions &&  
                      <QuizQuestion
                      questions={questions}
                      setQuestions={setQuestions}
                      totaltime={totaltime}
                      asseId={ass_id}
                      userId={userId}
                      mock={window?.location?.search =="?mock=true"}
                      // handleSubmitData={handleSubmitData}
                     />
                    }
                
                  </div>
                </div>
              </div>
           
          {/* </main>
        </div> */}
      </>
    ) : (
      ''
      // <Loader />
    )}
  </>
  )
}
