import React from 'react'
import CountdownTimer from '../Assessment/Exam/CountdownTimer'

export default function QuestionTitle(props) {
    const {time, title, hasTimer, onTimeFinished} = props

    return (
        <div className='question_timer_header'>
            <div className='row'>
                <div className='col-sm-12 col-lg-6 col-md-6 col-xl-6 bg-red question_count'>
                    <p className='question_count_btn'>{title}</p>
                </div>
                    {hasTimer && <div className='col-sm-12 col-lg-6 col-md-6 col-xl-6'>
                        <CountdownTimer time={time} autoSubmitData={onTimeFinished}/>
                </div>}
            </div>
        </div>
    )
}

// orange-bb-color rounded-start-3 border-bottom border-2
// orange-bg-color rounded-3 text-white