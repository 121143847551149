import { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import PdfViewer from '../../Materials/PdfViewer';

import { Document, Page, pdfjs } from "react-pdf";
import FlipBook from "./FlipBook";
import { removeBase64Prefix } from "../../../../util/Utilities";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function FlipBookViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState(null);

  const { fileType, filePath } = props;
  console.log("LoadContent", filePath);
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    let url = null;
    if (filePath) {
      const byteCharacters = atob(removeBase64Prefix(filePath));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create URL for the Blob
      url = URL.createObjectURL(blob);
      setPdfUrl(url);
    }
    // Clean up URL when component unmounts
    return () => URL.revokeObjectURL(url);
  }, [filePath]);

  useEffect(() => {});
  // const url = URL.createObjectURL(base64toBlob(filePath));

  return (
    <>
      {/* <div className="container-fluid"> */}
        <div className="row">
          <div className="col-12">
            <div className="card-body">
              {/* <div style={{ height: '100%' }} key={Math.random()}> */}
              {/* {filePath && <FileViewer
                                    fileType={fileType}
                                    filePath={filePath}
                                    onError={onError}
                                />} */}

              {pdfUrl && (
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  {numPages && <FlipBook numPages={numPages} />}
                </Document>
              )}

              {/* </div> */}
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
