import React, { useState, useEffect } from "react";
import CourseItem from "./CourseItem";
import "./course.scss";
import { filtergetByUserIdUnit } from "../../../services/ApiCaller/content/unit";
import { apiAction } from "../../../app/network_calls/api";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import ErrorMsg from "../../DefaultComponent/ErrorMsg";
import Loader from "../../DefaultComponent/Loader";

const Course = () => {
    const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [errorObj, setErrorObj] = useState({});
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);

  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed ? false : true
  );
  const { keycloak } = useKeycloak();

  const [allUnit, setAllUnit] = useState([]);
  const [userlistid, setUserListid] = useState();
  const [CourseImg, setCourseImg] = useState("");

  useEffect(() => {
    fetchModerateUserList();
    fetchUrl();
    return () => {console.log("COURSE COMPONENT UNMOUNT TRIGGERED")}
  }, []);



  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };
  const manageRedirection = (courseItemData) => {
    const regex = /[^a-zA-Z0-9\s]/g;
    const cName = courseItemData.name.replace(regex, "-")
    navigate(`${cName}/${courseItemData.id}`, {state: courseItemData});

  };

  //VIEW MODERATE USER LIST
  const fetchModerateUserList = () => {
    let filterdata =
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.sub;
    const response = getUserByUserId(filterdata);
    response.then(
      (success) => {
        console.log("USER ID USER ID", success)
        setUserListid(success.id);
        fetchUnit(success.id)
      },
      (error) => {}
    );
  };

  const fetchUnit = (userId) => {
    setLoading(true);
    setAllUnit([]);
    // const response = filterUnit(Data)
    const response = filtergetByUserIdUnit(userId);
    response.then(
      (success) => {
        setLoading(false);
        setAllUnit(success);
        setCourseImg(success.coverImage);
        console.log(success.id,"iddd3")
      },
      (error) => {
        setLoading(false);
        setErrorObj(error)
        setShowpopup(true);
        setPopup(true)
      }
    );
  };

  const fetchCourses = async () => {
    const coursesResponse = await apiAction({ url: "", method: "get" });

    if (coursesResponse) {
      if (coursesResponse.success) {
      } else {
      }
    }
  };

  return (
    <React.Fragment>
     {!loading ? (
      <div className="course_main">
        <div className="row">
          {allUnit &&
            allUnit.map((item, index) => {
              return (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-sm-12">
                  <CourseItem
                    module="6"
                    currentStatus="100"
                    completionStatus="100"
                    manageRedirection={manageRedirection}
                    description=""
                    coursedata={item}
                    buttonEnable={true}
                  />
                </div>
              );
            })}
        </div>
        {showPopup && (
              <ErrorMsg
                errorStatus={errorObj}
                setTrigger={setShowpopup}
                trigger={popup}
              />
            )}
      </div>
    ): (
          <Loader />
        )}
    </React.Fragment>
  );
};

export default Course;
